import React from 'react'
import './secopsecops.css'
import CountUp from 'react-countup';

function DevsecopsExperience() {
  return (
    <div>
        <div className='container-fluid bg-color-devops'>
    <div className='container'>
               <div className=" row experience mt-5 mb-5">    
                 <div className="col-lg-3 text-center border-box-service">
                 <p >
                 <CountUp start={0} end={191} delay={0}>
                 {({ countUpRef }) => (
                   <div >
                     <span className='font-devops2' ref={countUpRef} /><br/> <h5>SECURITY SERVICES</h5>
                   </div>
                 )}
                </CountUp>
                 </p>
                 </div>
                 <div className="col-lg-3 text-center border-box-service">
                 <p >
                 <CountUp start={0} end={21} delay={0}>
                 {({ countUpRef }) => (
                   <div >
                     <span className='font-devops2' ref={countUpRef} /><br/> <h5>SECURITY PROJECTS</h5>
                   </div>
                 )}
                </CountUp>
                 </p>
                 </div>

                 
                 <div className="col-lg-3 text-center border-box-service">
                 <p >
                 <CountUp start={0} end={11} delay={0}>
                 {({ countUpRef }) => (
                   <div >
                     <span className='font-devops2' ref={countUpRef} /><br/>  <h5>SECURITY EXPERTS</h5>

                   </div>
                 )}
                </CountUp>
                 </p>
                 </div>
                 
                 <div className="col-lg-3 text-center ">
                 <p >
                 <CountUp start={0} end={101} delay={0}>
                 {({ countUpRef }) => (
                   <div >
                     <span className='font-devops2' ref={countUpRef} /> <br/> <h5>SECURITY POSTS</h5>
                   </div>
                 )}
                </CountUp>
                 </p>
                 </div>
               </div>
               </div>

 </div>
    </div>
  )
}

export default DevsecopsExperience