import StserviceItem from "Components/AboutServiceItems";
import React from "react";
import { Reveal } from "react-reveal";
import "../AgencyAbout/about.css";

export default function AboutServices() {
  return (
    <div>
      {" "}
      <section className="agency_service_area bg_color">
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            {/* You SaasLand wherever your
            <br /> business agency */}
          </h2>
          <div className="row mb_30">
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1100}>
                <StserviceItem
                  iShap="icon_who.png"
                  Sicon="ti-panel"
                  text="Who?"
                  description="Team of professionals with 18+ years of IT experience, fully dedicated to technology-focused solutions for best quality IT services and always available support to serve you anywhere, anytime. We aim to provide you with the best development and operational assistance for your IT infrastructure."
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1400}>
                <StserviceItem
                  iShap="icon_what.png"
                  Sicon="ti-layout-grid2"
                  text="What.?"
                  description="Our skilled professionals are aware of the code and application, so it is not just hosted server or infrastructure support, but a complete IT solution along with best development, operational, and security practices. We promise to serve you with better professionals and the best tech team."
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <StserviceItem
                  iShap="icon_how.png"
                  Sicon="ti-gallery"
                  text="How..?"
                  description="We are here on a mission to increase product availability, by helping programmers focus on code and owners to focus on business by establishing the best managerial and operational culture that is more scalable, cost-effective, and have fewer interventions and errors."
                />
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
