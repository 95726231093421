import React from 'react';

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PHP from "../../assets/image/php1.png";
import NGINX from "../../assets/image/mean1.png";
import JAVA from "../../assets/image/javainfo1.png";
import PYTHON from "../../assets/image/pythoninfo1.png";




import "./tab.css";

function Tabcard() {
  return (
    <>
      <div className="container-fliud ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center m-4">
                <h1 className="tabs-font-size">
                  Highly Optimized <span className="tab-color">Web Engines</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 ">
        <Tabs
          defaultActiveKey="PHP"
          className="mb-3 tabs-control d-flex tabs-responsive "
          fill
        >
          <Tab eventKey="PHP" title="PHP" tabClassName="myclass key-color">
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="tabs-font-size">
                      <span className="tab-color">PHP Stack</span> design to deliver optimal performance, faster response and security,
                      secret ingredients combined with Nginx, Varnish, Redis, PHP, OpCache, Apache, MySQL,
                      Memcached along with optimized configurations.
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={PHP} alt="php" className="server2 image-responsive" width={650} />
                  </div>

                </div>

              </div>

            </div>
          </Tab>
          <Tab
            eventKey="MEAN/MERN"
            title="MEAN/MERN"
            tabClassName="myclass key-color"
          >
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="tabs-font-size">
                      <span className="tab-color">MEAN STACK</span> special configuration gives the performance that speeds up and secures the application,
                      secret ingredients combined with Nginx, Node,
                      Angular, NPM, MySQL/PostgresSQL, or MongoDB, Redis and Memcached.
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={NGINX} alt="php" className="server2 image-responsive" width={650} />
                  </div>

                </div>

              </div>

            </div>
          </Tab>
          <Tab
            eventKey="JAVA"
            title="JAVA"
            tabClassName="myclass key-color"
          >
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="tabs-font-size">
                      <span className="tab-color">Java Stack</span> configured for better security, performance and response time,
                      secret ingredients combined with Nginx, Elasticache,
                      Tomcat, MySQL/PostgresSQL, Memcached.th optimized configurations.
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={JAVA} alt="php" className="server2 image-responsive" width={650} />
                  </div>

                </div>

              </div>

            </div>
          </Tab>

          <Tab eventKey="PYTHON" title="PYTHON" tabClassName="myclass key-color">
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="tabs-font-size">
                      <span className="tab-color">Python Stack</span> configured for better security, performance and response time,
                      secret ingredients combined with Nginx, Elasticache,
                      Python, MySQL/PostgresSQL, Memcached.th optimized configurations.
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={PYTHON} alt="python" className="server2 image-responsive" width={650} />
                  </div>

                </div>

              </div>

            </div>
          </Tab>

        </Tabs>
      </div>
    </>
  );
}

export default Tabcard;
