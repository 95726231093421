import React from 'react'
import Card from "react-bootstrap/Card";
import "./devsecops.css";
import { Icon } from '@iconify/react';


function Devsecops() {
  return (
    <div className='container devsecops-font-family'>

      <div className='row mt-5'>

        <div className='col-lg-12 text-center'>
          <h1>Prominent Services for IT Security</h1>
        </div>

      </div>

      <div className='row'>


        <div className="col-lg-6 ">

          <Card border="light" className="devsecops-card devsecops-animation m-4 ">
            <Card.Body>
              <Card.Title className='devsecops-span'><Icon icon="fa-solid:user-shield" width="60" height="48" /> Secure Management Systems</Card.Title>
              <Card.Text>
                <p className=''>Our security system is composed of most modern management programs,
                  user-friendly interface, and error-proof conduct methodology.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-6">

          <Card border="light" className="devsecops-card devsecops-animation m-4 ">
            <Card.Body>
              <Card.Title className='devsecops-span'><Icon icon="fa-solid:shield-alt" width="60" height="48" /> Efficient Data Security</Card.Title>
              <Card.Text>
                <p className=''>During business transformations, there're possibilities for errors,
                  but our highly advanced data management system spares no room for that. </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>


        <div className="col-lg-6 ">

          <Card border="light" className="devsecops-card devsecops-animation m-4 ">
            <Card.Body>
              <Card.Title className='devsecops-span'><Icon icon="fa-solid:cogs" width="60" height="48" /> Reliable Multi-function Technology</Card.Title>
              <Card.Text>
                <p className=''>You can totally count on DDevOps for all things related to IT management,
                  infrastructure design, automation, and other security services.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-6 ">

          <Card border="light" className="devsecops-card devsecops-animation m-4 ">
            <Card.Body>
              <Card.Title className='devsecops-span'><Icon icon="fa-solid:users-cog" width="60" height="48" /> Highly Professional Staffs</Card.Title>
              <Card.Text>
                <p className=''>Having obtained the official & formal training in IT
                  technology and technical fields, our staffs know more than what you expect to provide unmatched technical assistance. </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

    </div>
  )
}

export default Devsecops