import DevsecopsExperience from 'Components/devsecopsWorkexperience';
import DevSecOpsCard from 'Components/DevSevOPsCards';
import React from 'react'
import "./devsecopsservices.css";
import Testimonials2 from "Components/Testimonials2";
import FlipCard from 'Components/FlipCard';
import Button from "react-bootstrap/Button";
import Infographics from "assets/image/inforgraphsics.png";
import ServiceDetails from "assets/file/DDevOps-Company-Service-Details-2021.pdf";
import minutes30 from "../../assets/image/flipcard/30-minutes.jpg";
import ai from "../../assets/image/flipcard/ai.jpg";
import intentBased from "../../assets/image/flipcard/intent-based.jpg";
import worldMap from "../../assets/image/flipcard/world-map.jpg";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import SecurityAddon from 'Components/SecurityAddon';
import devsecopsBanner from "../../assets/image/devsecops-bg.jpg";

function DevsecopsService() {
  return (
    <>
      <MetaTags>
        <title>DevSecOps Services | Dedicated DevSecOps Service</title>
        <meta name="description" content="DevSecOps service is an infusion of a security mindset in the execution of secure operations along with agile methods, and DevOps practice." />
        <meta name="keywords" content="Dedicated DevSecOps Services, Dedicated DevSecOps Services, DevOps Services, DevSecOps Services, DevOps Solution, DDevOps" />
      </MetaTags>

      <div className="secops-gradiant">
        <img src={devsecopsBanner} />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt-5">
            <h3 className="heading-devsecops-services">
              <mark className="mark-dev">50+</mark>YEARS
              <br /> OF
              <br /> IT OPERATIONS
            </h3>
            <h1 className='mt-5 envolved-devsecops-margin'>Envolved into <br /><span className='devsecops-company-span'>DevSecOps</span></h1>
          </div>
          <div className="col-lg-6 mt-5">
            <div className="hr3"></div>
            <h4 className="devsecops-service-font12">
              DevSecOps is the infusion of a security mindset in the execution of Development Operations.
              Security always needs to be center align along with agile methods, automation, and DevOps practice.
            </h4>
            <p className='devsecops-offer-margin'>We recommend getting yourself protected right from the start to meet security expectations,
              from Agile to DevOps to DevSecOps. The Next Evolution to put a strong focus on the needs of your
              IT security to figure out the best possible solutions that fit your demand.</p>
          </div>
        </div>
      </div>

      <div>
        <DevsecopsExperience />
      </div>

      <div>
        <DevSecOpsCard />
      </div>

      <div className="container resource-bg my-4">
        <div className="row">
          <div className="col-lg-6 button-margin">
            <h3 class="resource-heading">PENETRATION</h3>
            <h1 className=" main-heading ">
              <span className="heading-span">Manage your website security with</span>
              <br />
              Dedicated DevOps
            </h1>
            <a href={ServiceDetails} target="_blank" rel="noreferrer" >
              <Button className="btn button-background mt-3">
                Download sample
              </Button>
            </a>
          </div>

          <div className="col-lg-6 text-center">
            <img src={Infographics} alt="eccomerce" className="eccomerce-responsive" width={450} height={350} />
          </div>


        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <h1>Securing Your Success Through<span className="develop-span"> DevSecOps Solution</span></h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8">
            <p>
              Our enterprise security solutions align in a perfect way to introduce you to the new DevSecOps approach, we provide different SaaS-based solutions combined as ‘Security as a Service’, to get ultimate protection against security threats. SecOps team audit your site(s) with the knowledge of ethical hackers, we scan applications and servers for vulnerabilities along with top 10 OWASP security risk, we set up and provide centralized credentials protection, we do risk management, and evaluate internal and external threads, we filter traffic for good to protect you from malicious connections like SQL Injection, Cross-Site Scripting(XSS), directory traversal and DDOS, we got proactive security systems that triggers as needed, and we are always ready to give you the complete enterprise security solutions as per your requirement.
            </p>
          </div>
          <div className="col-lg-4"></div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <ul> <li><strong>Network and Infrastructure Security</strong></li> <li><strong>System and Endpoint Security</strong></li> <li><strong>Web Application Firewall</strong></li> <li><strong>Ransomware Protection</strong></li> <li><strong>Data encryption and Worm storage</strong></li> <li><strong>Gbps DDOS Protection against millions of the packets per second</strong></li> <li><strong>UDP, TCP, ICMP, SYN, and HTTP Flood Protection</strong></li> <li><strong>Protection against HTTP/HTTPS, XML RPC, Bandwidth Leeching, Botnet etc</strong></li> <li><strong>Security Audit and Compliance</strong></li> </ul>
          </div>
          <div className="col-lg-4 mt-5 pt-5">
            <Link to="/Contact">
              <Button className="btn button-background" style={{ width: 'auto' }}>
                Contact Security Consultant
              </Button>
            </Link>
          </div>
        </div>
        <div className="row">
          <FlipCard
            title="Quick Implementation"
            frontDescription="Our security implementations are quick and easy to deploy, it hardly take 30 minutes to implement our security plans on top of any infrastructure along with rapid response time of 30 minutes."
            backBgImage={minutes30}
          />
          <FlipCard
            title="Deep Inspection"
            frontDescription="The deep inspection provides a full suite of security and control, this helps to integrate protection in several ways. An automated protection control system gives you a state of the art application protection whenever you need it."
            backBgImage={worldMap}
          />
          <FlipCard
            title="AI Traffic Filtering"
            frontDescription="We use two major AI techniques. Speed filter check flow of packets frequently to trigger alerts for traffic filters to check and analyze incoming hits to make rules and filter packets from transport to the application layer."
            backBgImage={ai}
          />
          <FlipCard
            title="Multiple Networks"
            frontDescription="We got multiple mitigation centers to minimize the effect of attacks on the infrastructure by catching and re-routing possibly malicious traffic to mitigate on different networks."
            backBgImage={intentBased}
          />
        </div>
      </div>

      <div>
        <SecurityAddon />
      </div>

      <div>
        <Testimonials2 />
      </div>
    </>
  )
}

export default DevsecopsService