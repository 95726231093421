import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "assets/image/ddevops-logo.png";
import "./navbar.css";
import { Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'


function Navbars() {

  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        handleShow(true);
      }
      else handleShow(false);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        handleShow(false);
      });
    };
  }, []);

  return (
    <>

      <Navbar expand="lg" sticky="top" fixed="top" className={` Nav ${show && "nav_black"}`} >
        <Container >
          <a href="/">
            <img src={Logo} alt="logo" className="top-logo-size-control" width={220} />
          </a>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-center">

              <Nav.Link >
                <Link to="/" className="nav-home-margin nav-text-color nav-collapse tab" >Home</Link>
              </Nav.Link>

              <Nav.Link >
                <Link to="Company" className="nav-home-margin nav-text-color nav-collapse tab"> Company</Link>
              </Nav.Link>


              <NavDropdown title="Services" className="Nav-color-sub" id="nav-dropdown">
                <NavDropdown.Item>
                  <Link to="DevopsServices" className='text-dark Nav-bar-submenu'> DevOps</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="DevsecopsService" className='text-dark Nav-bar-submenu'>DevSecOps</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link >
                <Link to="Develop" className="nav-home-margin nav-text-color nav-collapse tab" >Develop</Link>
              </Nav.Link>


              <NavDropdown title="Partnership" className="nav-text-color tab" id="nav-dropdown">

                <NavDropdown.Item>
                  <Link to="Partnership" className='text-dark Nav-bar-submenu'>Technology</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="Whitelabel" className='text-dark Nav-bar-submenu'>Whitelabel</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link >
                <Link to="Contact" className="nav-home-margin nav-text-color nav-collapse tab" >Contact</Link>
              </Nav.Link>

              <a href="https://blogs.ddevops.com/" class="nav-link nav-text-color nav-collapse tab" target="_blank" rel="noopener noreferrer">Blog</a>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );
}

export default Navbars;
