import React from 'react'
import './work.css'
import CountUp from 'react-countup';


function Work() {

  return (
    <div className='container-fluid bg-color'>
      <div className='container'>
        <div className=" row experience">

          <div className="col-lg-6 ">
            <p className='font-pioneer' >
              <span className='font'>10</span> YEARS OF PROFESSIONAL EXCELLENCE
            </p>
            <h1 className='text-dark margin-pioneer'>Pioneer of <span className='color'> DevOps <br /> Services </span></h1>
          </div>

          <div className="col-lg-3 text-center">
            <p >
              <span className='font2'>5</span> <br />TECHNOLOGY PARTNERSHIP
            </p>
            <p >
              <CountUp start={0} end={100} delay={0}>
                {({ countUpRef }) => (
                  <div >
                    <span className='font2' ref={countUpRef} /><span className='font2'>+</span> <br /> DIRECT CLIENTS
                  </div>
                )}
              </CountUp>
            </p>
          </div>

          <div className="col-lg-3 text-center ">
            <p >
              <span className='font2 '>11</span><br />WHITELABEL SOLUTIONS
            </p>
            <p >
              <CountUp start={0} end={1000} delay={0}>
                {({ countUpRef }) => (
                  <div >
                    <span className='font2' ref={countUpRef} /><span className='font2'>+</span> <br />INDIRECT CLIENTS
                  </div>
                )}
              </CountUp>
            </p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Work