import React from 'react'
import './portfolio.css'


import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';

import HouseOfChenille from "../../assets/image/portfolio/hcc-480x360@2x.png";
import DesignsIntellect from "../../assets/image/portfolio/desgin-480x360@2x.png";
import Hoojan from "../../assets/image/portfolio/hoojan-480x360.png";
import ImtiazSuperMarket from "../../assets/image/portfolio/imtiaz-480x360@2x.png";
import Goprot from "../../assets/image/portfolio/go-480x360@2x.png";
import Burque from "../../assets/image/portfolio/burqye.png";
import Everywater from "../../assets/image/portfolio/everywater.png";
import Foree from "../../assets/image/portfolio/foree.png";
import kundakari from "../../assets/image/portfolio/kundakari.png";
import Mysurefit from "../../assets/image/portfolio/mysurefit.png";
import Uhfsolutions from "../../assets/image/portfolio/uhfsolutions.png";

function Portfolio() {
    return (

        <div className='container'>

            <div class="row">
                <div class="col-lg-12 text-center mt-5">
                    <h1>
                        Portfolio
                    </h1>
                </div>
            </div>

            <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={true} arrows={false}
                className="container d-flex justify-content-center"
                responsive={[{
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }, {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }]} >


                <div className='test-card-margin card-responsive400 '>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://houseofchenille.com/" target='_blank' rel="noreferrer">

                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >

                                                <img src={HouseOfChenille} alt="icon" className="portfolio-icon card-icon-animation" />

                                                <Card.Title className='card-title-margin'>House of chenille</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-coll card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375'>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://designsintellect.com.au/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={DesignsIntellect} alt="icon" className="portfolio-icon card-icon-animation2 " />


                                                <Card.Title className='card-title-margin'>Designs Intellect</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card-responsive400 card-responsive428'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://www.hoojan.com" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Hoojan} alt="icon" className="portfolio-icon card-icon-animation3" />


                                                <Card.Title className='card-title-margin' >Hoojan</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-cont card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375'>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://imtiaz.com.pk" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={ImtiazSuperMarket} alt="icon" className="portfolio-icon card-icon-animation" />


                                                <Card.Title className='card-title-margin'>Imtiaz Super Market</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="http://www.goprot.com" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Goprot} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>Goprot</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://www.burque.com.pk/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Burque} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>Burque</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://everywater.com/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Everywater} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>Everywater</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="http://foree.co/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Foree} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>Foree</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://www.kundakari.com/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={kundakari} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>kunda kari</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://mysurefit.co/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Mysurefit} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>Mysurefit</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='test-card-margin card-responsive400'>
                    <div className='card-responsive360 '>
                        <div className='card-responsive375 '>
                            <div className='card-responsive-414'>
                                <div className='card-responsive-1440'>
                                    <a href="https://uhfsolutions.ae/" target='_blank' rel="noreferrer">
                                        <Card border="light" className="test-card card-animation1 m-2 ">
                                            <Card.Body className='p-4' >


                                                <img src={Uhfsolutions} alt="icon" className="portfolio-icon card-icon-animation2" />


                                                <Card.Title className='card-title-margin'>UHF Solutions</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Slide>

            <div className="row ">
                <div className="col-lg-12 mt-5 mb-5 text-center ">
                    <h6 className='joint-text-size'>
                        We build powerful solutions that could be customized for your business needs.
                    </h6>
                    <Link to="/Contact">
                        <Button className="btn button-background" style={{ width: 'auto' }}>
                            Lets Talk
                        </Button>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default Portfolio