import React from 'react'
import './developcards.css'
import Button from 'react-bootstrap/Button';
import { GrFormCheckmark } from 'react-icons/gr';
import { AiOutlineCloseCircle } from 'react-icons/ai';



import Card from "react-bootstrap/Card";

import Flip from 'react-reveal/Flip';


function DevelopCard() {
  return (
    <div className='container'>

      <div className='row mt-5'>
        <div className='col-lg-12 text-center'>
          <h1>DEVELOPMENT <span className='develop-span'>PLANS</span></h1>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-4 ">
          <Flip right>

            <Card border="light" className="develop-card develop-animation1 m-2 ">
              <Card.Body >

                <div className='mb-2'>
                  {/* <FcAutomatic size={50} className='color'/> */}
                </div>

                <Card.Title className='develop-align'>BUILD AND <span className='develop-span'>DEPLOY</span></Card.Title>
                <Card.Text>
                  <p className='develop-align'>Suitable for small applications </p>
                  <div className='develop-align'>
                    <a href="/Contact">
                      <Button className="develop-card2-button">
                        Get Started
                      </Button></a>
                  </div>
                  <p className='mt-4 card-text-margin'> <GrFormCheckmark size={20} color="red" />Development</p>
                  <p className='card-text-margin'> <GrFormCheckmark size={20} /> Deployment</p>
                  <p className='card-text-margin'><AiOutlineCloseCircle size={20} /> Operations</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Maintenance</p>
                  <p className='card-text-margin'><AiOutlineCloseCircle size={20} /> Minor Changes</p>
                  <p className='card-text-margin'><AiOutlineCloseCircle size={20} /> Major Changes</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Flip>

        </div>


        <div className="col-lg-4">
          <Flip top>

            <Card border="light" className="develop-card develop-animation1 m-2">
              <Card.Body >

                <div className='mb-2'>
                  {/* <FcAutomatic size={50} className='color'/> */}
                </div>

                <Card.Title className='develop-align'>BUILD AND <span className='develop-span'>SUPPORT</span></Card.Title>
                <Card.Text>
                  <p className='develop-align'>Suitable for standard applications </p>
                  <div className='develop-align'>
                    <a href="/Contact">
                      <Button className="develop-card2-button">
                        Get Started
                      </Button></a>
                  </div>
                  <p className='mt-4 card-text-margin'> <GrFormCheckmark size={20} />Development</p>
                  <p className='card-text-margin'> <GrFormCheckmark size={20} /> Deployment</p>
                  <p className='card-text-margin'><AiOutlineCloseCircle size={20} /> Operations</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Maintenance</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Minor Changes</p>
                  <p className='card-text-margin'><AiOutlineCloseCircle size={20} /> Major Changes</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Flip>

        </div>

        <div className="col-lg-4">
          <Flip bottom>

            <Card border="light" className="develop-card develop-animation1 m-2">
              <Card.Body >

                <div className='mb-2'>
                  {/* <FcAutomatic size={50} className='color'/> */}
                </div>

                <Card.Title className='develop-align'>BUILD AND <span className='develop-span'>OPERATE</span></Card.Title>
                <Card.Text>
                  <p className='develop-align'>Suitable for large applications </p>
                  <div className='develop-align'>
                    <a href="/Contact">
                      <Button className="develop-card2-button">
                        Get Started
                      </Button></a>
                  </div>
                  <p className='mt-4 card-text-margin'> <GrFormCheckmark size={20} />Development</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Deployment</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Maintenance</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Minor Changes</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Operations</p>
                  <p className='card-text-margin'><GrFormCheckmark size={20} /> Major Changes</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Flip>

        </div>

      </div>

    </div>

  )
}

export default DevelopCard