import React, { useState } from "react";
import "./contact.css";
import Contact from "../../assets/image/contact.png";
import MetaTags from 'react-meta-tags';
import AnimatedText from 'react-animated-text-content';
import Discover from "Components/Tab/discover";
import sgMail from '@sendgrid/mail';
import axios from 'axios';

// Set SendGrid API key
sgMail.setApiKey("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");

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://beta.ddevops.com/sendmail.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data.status === 'success') {
        alert('Email sent successfully');
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }

  };

  return (
    <>
      <MetaTags>
        <title>Dedicated DevOps | Design and Develop A Perfect Application</title>
        <meta name="description" content="Design and Develop A Perfect Application | Web And App Development Packed With Ultimate Features Of Your Choice." />
        <meta name="keywords" content="Design and Develop, Dedicated DevOps Services, DevOps Services, DevSecOps Services, Perfect Application, DDevOps" />
      </MetaTags>
      <div className="contact-gradiant">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-12 text-center">
                <AnimatedText className="f_d d_700 d_size_50 d_color d_height50 md_20"
                  type="chars" // animate words or chars
                  animation={{
                    x: '200px',
                    y: '-20px',
                    scale: 10,
                    ease: 'ease-in-out',
                  }}
                  animationType="rifle"
                  interval={0.06}
                  duration={0.8}
                  tag="p"
                  includeWhiteSpaces
                  threshold={0.1}
                  rootMargin="20%"
                >
                  Preparing Sucess Through Technology Services
                </AnimatedText>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='container '>
          <div className='row'>
            <div className='col-lg-12 text-center '>
              <h1 className="heading">
                Let us help you!
              </h1>
              <p>It's our pleasure to have a chance to cooperate.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="row ">
          <div className="col-lg-6" >
            <div className="p-5 letterbox-image-container">
              <img src={Contact} alt='contact' width={350} className="letterbox-image" />
            </div>
            <div className="mt-2 contact-you contact-heading request-responsive400">
              <strong align="left" className="contact-span contact-you ">REQUEST A CALLBACK</strong>
              <p align="left" className="contact-you ">We Will contact in the shortest time.</p>
            </div>
          </div>

          <div className="col-lg-6 contact-form " >
            <div className="contact-img contact-img1440">
              <div className=" message-img message-img-responsive400 message-img-responsive428 message-img-responsive375 message-img-responsive1440">
                <form onSubmit={handleSubmit} className="contact-form">
                  <div className="form-group">
                    <div className="row">
                      <div className="">
                        <div>
                          <input
                            placeholder="Name"
                            id="name"
                            type="text"
                            className="contact-field contact-responsive400 contact-resposive428 contact-resposive375"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mt-2">
                          <input
                            placeholder="Email"
                            id="email"
                            type="email"
                            className="pl-5 email-field email-responsive-400 email-responsive-428 email-responsive-375"
                            value={formData.email}
                            onChange={handleChange}
                            aria-describedby="emailHelp"
                          />
                        </div>
                        <div className="form-group mt-2">
                          <textarea
                            placeholder="Message"
                            id="message"
                            className="text-field text-responsive-400 text-responsive-428 text-responsive-375"
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-1">
                          <button type="submit" className="button-ddevops button-ddevops400 button-responsive428 button-responsive375">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12">
            <Discover />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 mt-5">
            <h4>North America</h4>
            <p>2770 Coastal Bay DR APT 105, Orange City, FL 32763.<br />
              <a href="mailto:contact@ddevops.com">contact@ddevops.com</a><br />
              <a href="tel:(+1)3867489010">(+1)3867489010</a>
            </p>
          </div>
          <div className="col-lg-4 mt-5">
            <h4>Asia</h4>
            <p>A 138 Dream City, Wireless Gate, Karachi, Pakistan.<br />
              <a href="mailto:support@ddevops.com">support@ddevops.com</a><br />
              <a href="tel:(+92)3033338766">(+92)3033338766</a>
            </p>
          </div>
          <div className="col-lg-4 mt-5">
            <h4>Europe</h4>
            <p>Stråkvägen 1,169 35 Solna, Stockholm Sweden<br />
              <a href="mailto:contact@ddevops.com">contact@ddevops.com</a><br />
              <a href="tel:(+46)793466263">(+46)793466263</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
