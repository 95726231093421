import React from 'react';

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TECH from "../../assets/image/tech-assurance.webp";
import WHOWEARE from "../../assets/image/who_we_are_img.webp";
import INFOSECURITY from "../../assets/image/info-security-image.webp";
import AmazonServices from "../../assets/image/amazon-services.png";
import AlibabaCloud from "../../assets/image/alibaba-cloud.jpg";
import LinodeLLC from "../../assets/image/linode-llc.png";
import MicrosoftAzure from "../../assets/image/microsoft-azure.png";
import { GiCheckMark } from "react-icons/gi";

import "./tab.css";

function Discover() {
    return (
        <>
            <div className="container-fliud ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center m-4">
                                <h1 className="tabs-font-size">
                                    We’ve been thriving in <span className="tab-color">10 years</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 ">
                <Tabs
                    defaultActiveKey="About Services"
                    className="d-flex tabs-responsive "
                    fill
                >
                    <Tab eventKey="About Services" title="About Services" tabClassName="myclass key-color">
                        <div>
                            <div className="container">

                                <div className="row">

                                    <div className="col-lg-4 mt-5 text-center">
                                        <img src={TECH} style={{ width: '100%', 'margin-bottom': '20px' }} />
                                        <h4>Technology Assurance System</h4>
                                        <p>At DDevOps, we have a holistic and integrated approach towards core modernization to experience technological evolution.</p>

                                    </div>
                                    <div className="col-lg-4 mt-5 text-center">
                                        <img src={WHOWEARE} style={{ width: '100%', 'margin-bottom': '20px' }} />
                                        <h4>Highly Professional Staffs</h4>
                                        <p>Highly experienced, and having obtained the official certificate & formal training in Information Technology and Technical fields, our professionals know best.</p>

                                    </div>
                                    <div className="col-lg-4 mt-5 text-center">
                                        <img src={INFOSECURITY} style={{ width: '100%', 'margin-bottom': '20px' }} />
                                        <h4>Info Security Management</h4>
                                        <p>Information security has been a rising issue lately due to a series of scandals from big companies, rest assured, we're here.</p>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </Tab>
                    <Tab
                        eventKey="CLOUD PARTNERS"
                        title="CLOUD PARTNERS"
                        tabClassName="myclass key-color"
                    >
                        <div>
                            <div className="container">

                                <div className="row">

                                    <div className="col-lg-3 mt-5 text-center">
                                        <img src={AmazonServices} style={{ width: '50%', 'margin-bottom': '20px' }} />
                                        <h4>Amazon Web Service</h4>
                                        <p>For more than 7 years, our company’s is providing revolutionary support and training for businesses of every size.</p>

                                    </div>
                                    <div className="col-lg-3 mt-5 text-center">
                                        <img src={MicrosoftAzure} style={{ width: '50%', 'margin-bottom': '20px' }} />
                                        <h4>Microsoft Azure</h4>
                                        <p>For more than 3 years, our company’s is providing revolutionary support and training for businesses of every size.</p>

                                    </div>
                                    <div className="col-lg-3 mt-5 text-center">
                                        <img src={LinodeLLC} style={{ width: '50%', 'margin-bottom': '20px' }} />
                                        <h4>Linode LLC</h4>
                                        <p>For more than 7 years, our company’s is providing revolutionary support and training for businesses of every size.</p>

                                    </div>
                                    <div className="col-lg-3 mt-5 text-center">
                                        <img src={AlibabaCloud} style={{ width: '50%', 'margin-bottom': '20px' }} />
                                        <h4>Ali Baba Cloud</h4>
                                        <p>For more than 1 years, our company’s is providing revolutionary support and training for businesses of every size.</p>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </Tab>
                    <Tab
                        eventKey="OUR MISSION"
                        title="OUR MISSION"
                        tabClassName="myclass key-color"
                    >
                        <div>
                            <div className="container">

                                <div className="row">

                                    <div className="col-lg-6 mt-5 pt-5">
                                        <p className="tabs-font-size">
                                            It’s better to achieve more than a little!
                                            Technological solutions play a vital role in improving our community.
                                        </p>
                                        <p>
                                            <GiCheckMark /> Create technological advance<br />
                                            <GiCheckMark /> Local environment improvement<br />
                                            <GiCheckMark /> Database construction security<br />
                                            <GiCheckMark /> Management engineering system<br />
                                        </p>

                                    </div>

                                    <div className="col-lg-6 my-5 text-center">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/Q_5hfx_Cd-E?si=a9PAUKGEoC4ulao6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </Tab>

                </Tabs>
            </div>
        </>
    );
}

export default Discover;
