import React from 'react'
import './devopswork.css'
import CountUp from 'react-countup';


function ServicesExperience({ type = "servicesExperience" }) {
  if (type === "company") {
    return (
      <div className='container-fluid bg-color-devops'>
        <div className='container'>
          <div className=" row experience mt-5 mb-5">

            <div className="col-lg-4 text-center border-box-service">
              <p >
                <CountUp start={0} end={1190} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /><br /> <h5 >HAPPY CLIENTS</h5>
                    </div>
                  )}
                </CountUp>
              </p>
            </div>
            <div className="col-lg-4 text-center border-box-service">
              <p >
                <CountUp start={0} end={91} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /><br /> <h5 >FINISHED PROJECTS</h5>
                    </div>
                  )}
                </CountUp>
              </p>
            </div>


            <div className="col-lg-4 text-center">
              <p >
                <CountUp start={0} end={47} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /><br />  <h5 >EXPERIENCE EXPERTS</h5>

                    </div>
                  )}
                </CountUp>
              </p>
            </div>
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div className='container-fluid bg-color-devops'>
        <div className='container'>
          <div className=" row experience mt-5 mb-5">

            <div className="col-lg-3 text-center border-box-service">
              <p >
                <CountUp start={0} end={177} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /><br /> <h5 >DEVOPS SERVICES</h5>
                    </div>
                  )}
                </CountUp>
              </p>
            </div>
            <div className="col-lg-3 text-center border-box-service">
              <p >
                <CountUp start={0} end={41} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /><br /> <h5 >DEVOPS PROJECTS</h5>
                    </div>
                  )}
                </CountUp>
              </p>
            </div>


            <div className="col-lg-3 text-center border-box-service">
              <p >
                <CountUp start={0} end={17} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /><br />  <h5 >DEVOPS ENGINEER</h5>

                    </div>
                  )}
                </CountUp>
              </p>
            </div>

            <div className="col-lg-3 text-center ">
              <p >
                <CountUp start={0} end={109} delay={0}>
                  {({ countUpRef }) => (
                    <div >
                      <span className='font-devops2' ref={countUpRef} /> <br /> <h5 >DEVOPS POSTS</h5>
                    </div>
                  )}
                </CountUp>
              </p>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default ServicesExperience