import React from "react";
import MetaTags from 'react-meta-tags';
import "./terms-of-service.css";

function TermsOfService() {
    return (
        <>
            <MetaTags>
                <title>Dedicated DevOps | Privacy Policy</title>
            </MetaTags>
            <div className="container terms-of-services">
                <div className="row">
                    <div className="col-lg-12 mt-5">

                        <h2>Assumptions</h2>
                        <ul>
                            <li>DDevOps will provide ongoing operational support maintenance as described in Service Level Agreement</li>
                            <li>DDevOps will not be responsible for any hardware failure and application bug, any problem related to infrastructure providers or any development issues with application.</li>
                            <li>All Subscription available on monthly, quarterly, biannually or yearly basis.</li>
                            <li>Services provided outside of scope of agreement are subject to an additional cost.</li>
                            <li>Major upgrades outside of the scope of agreement are treated as Hourly projects.</li>
                            <li>Changes to services will be communicated and documented in Service Charter Contract.</li>
                            <li>Service will be provided adherence to related policies, processes and procedures.</li>
                            <li>Scheduling requests will be conducted in accordance with service descriptions.</li>
                            <li>Support for non-subscribed servers and services are not included.</li>
                            <li>Backup and security settings are not a 100% guarantee against hardware loss or data corruption, and/or from the compromise application.</li>
                            <li>DDevOps is not responsible for loss and/or corrupted data that may occur when servicing client infrastructure.</li>
                            <li>Technical assistance and best effort is limited to couple of hour of troubleshooting assistance per incident.</li>
                            <li>Any assistance exceeding from 2 hours (per incident) will be notified and billed at hourly rate.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <h2>Uptime</h2>
                        <ul>
                            <li>DDevOps provide 99.9% guaranteed uptime, in case we unable to deliver guaranteed availability lost time will be refunded back to your account.</li>
                            <li>The Guaranteed uptime is limited to the server availability from infrastructure provider, any planned / unplanned maintenance from either side will be separated from it.</li>
                            <li>Upgrade, migration or any sort of disaster with the application itself will be separated.</li>
                            <li>Find the periods of potential downtime/unavailability associated with guaranteed uptime.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <table class="table-list2">
                            <tbody>
                                <tr>
                                    <td>Daily</td>
                                    <td>1m 26.4s</td>
                                </tr>
                                <tr>
                                    <td>Weekly</td>
                                    <td>10m 4.8s</td>
                                </tr>
                                <tr>
                                    <td>Monthly</td>
                                    <td>43m 49.7s</td>
                                </tr>
                                <tr>
                                    <td>Yearly</td>
                                    <td>8h 45m 57.0s</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <h2>Security Practices</h2>
                        <ul>
                            <li>DDevOps will configure server to comply with the system Security Standards and best practices, while security practices are meant to reduce the likelihood of hacking and other security compromises, DDevOps cannot guarantee against data loss due to security compromises caused by the application, outdated or vulnerable software.</li>
                            <li>DDevOps will not support servers that do not comply with known standards and policies. Server that fall out of compliance will immediately be unsubscribed from service and cannot be subscribed until policy standards are in place.</li>
                            <li>Servers known to have Restricted Data will only be supported by DDevOps if hosted in the Data Center or comparable secure environment. Departments are ultimately responsible for following standard practices regarding security of Restricted Data. DDevOps can provide technical consultation on implementing security tools for Restricted Data.</li>
                            <li>Clients or their users granted administrative rights to their application are responsible for following recommended security practices. If there is evidence that a machine has been compromised due to given rights, DDevOps reserves the right to revoke or restrict administrative rights from any or all users.</li>
                            <li>Client will make decisions on user access and authorization and will notify DDevOps to add or remove user access as they see fit.</li>
                            <li>DDevOps is not responsible for storing passwords for server &amp; application users. DDevOps will never ask a user for their passphrase but assist the user in setting up the password, DDevOps does not store a copy of the password anywhere so this will be the responsibility of the user and client to remember passwords to critical systems or files. DDevOps will assist the user in changing the password to regain access, though this ability may not always be available.</li>
                            <li>A compromised machine or application will immediately be taken offline until DDevOps can assure minimum security standards are met. Actions may include vulnerability scan, erasing and rebuilding machine and/or removing administrative rights.</li>
                        </ul>
                        <h2>Backups</h2>
                        <table class="table-list2">
                            <tbody>
                                <tr>
                                    <th>Backup Retention Policy (Onsite/Offsite)</th>
                                </tr>
                                <tr>
                                    <td>Daily 7</td>
                                </tr>
                                <tr>
                                    <td>Weekly 2</td>
                                </tr>
                                <tr>
                                    <td>Monthly 1</td>
                                </tr>
                                <tr>
                                    <td>Database (15 days)</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="container">
                            <p>&nbsp;Backup frequency can be increase according to the needs, there will be additional charges for offsite storage and configuration charges for additional backups.</p>
                            <p>&nbsp;</p>
                        </div>
                        <h2>Service Level Agreement</h2>
                        <div><em>DDevOps provides quality services to their client, maximum productivity backed up by this Service Level Agreement. This is an integral part of agreement, and can be reviewed, revised and support by additional stuff in the contract.</em></div>
                        <div>&nbsp;</div>
                        <div class="container">
                            <div class="title4">&nbsp;</div>
                            <p><em>This SLA addressed;</em></p>
                            <ul>
                                <li>The services DDevOps provides to the customer</li>
                                <li>Response times, availability, and maintenance associated with these services</li>
                                <li>Responsibilities of DDevOps as a Managed Service provider for subscription based services.</li>
                                <li>Define scope of service &amp; responsibilities of clients receiving subscription based services.</li>
                                <li>Processes for requesting services along with technical assistance and response time.</li>
                                <li>Review, reporting &amp; other process.</li>
                            </ul>
                        </div>
                        <h2>Scope Of Service</h2>
                        <p>DDevOps team of server administrators provides professional management of different servers, including in house infrastructure, dedicated, virtual &amp; cloud environments, supported versions of server software for Linux and Microsoft</p>
                        <p><em>Server support subscription includes the following:</em></p>
                        <ul>
                            <li>Provision of server(OS &amp; other software requirements).</li>
                            <li>Deployment and configuration of firewall.</li>
                            <li>Initial setup and configuration of server.</li>
                            <li>Installation and configuration of application along with required packages on server.</li>
                            <li>Monitoring of production applications, running services and server resources.</li>
                            <li>Documentation, configuration, and change log.</li>
                            <li>Maintenance and security update management.</li>
                            <li>Hardware upgrades, vertical/horizontal scaling such as memory, hard drive etc. as support by infrastructure/platform provider.</li>
                            <li>Configuration &amp; maintenance of onsite/offsite Backups and support for related tools.</li>
                            <li>Troubleshooting assistance for ongoing issues, fixing of technical problems, and providing best possible solution.</li>
                        </ul>

                        <div class="container">
                            <div class="title4">
                                <h2>&nbsp;</h2>
                            </div>
                        </div>

                        <table class="table-list2">
                            <tbody>
                                <tr>
                                    <th colspan="2">Managed Services</th>
                                </tr>
                                <tr>
                                    <td>Web Server Management</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Database Server Management</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Mail Server and Email Management</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>DNS Server and DNS Management</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Backup Server Management</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Control Panel Management</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Infrastructure Support</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Proactive Monitoring</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Support for Linux and Windows Platforms</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>OS Upgrade and Patches</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Server Security and Firewall Configuration</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Data Migration</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Application Migration</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Security Patches and Server Packages</td>
                                    <td>X</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="container">
                            <div class="title4">
                                <h2>&nbsp;</h2>
                            </div>
                        </div>
                        <table class="table-list2">
                            <tbody>
                                <tr>
                                    <th colspan="2">Data Protection (as per backup policy)</th>
                                </tr>
                                <tr>
                                    <td>Backup Configuration</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Users &amp; Permissions</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Ports &amp; Protocols</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Application(s) Data</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Configuration Data</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Databases</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>DR Assistance</td>
                                    <td>X</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="container">
                            <div class="title4">
                                <h2>&nbsp;</h2>
                            </div>
                        </div>
                        <table class="table-list2">
                            <tbody>
                                <tr>
                                    <th colspan="2">Other services</th>
                                </tr>
                                <tr>
                                    <td>SSL(HTTPS) Support</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Technical Support</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Security Assistance</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Technology Consultation</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Infrastructure Support</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <td>Technical Correspondence with other providers</td>
                                    <td>X</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="container">
                            <div class="title4">
                                <h2>&nbsp;</h2>
                            </div>
                        </div>
                        <table class="table-list2">
                            <tbody>
                                <tr>
                                    <th colspan="3">Ecommerce Operations and DDevOps Services</th>
                                </tr>

                                <tr>
                                    <td>Proxy Servers and Load Balancer</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Advanced Web Support (scripts, integration etc)</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Third party software installation</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Multi-tier Setup and Support</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Multi-Environment and Multi-Location setup</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Software Support (Including third party)</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Custom Server Setup</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Application Upgrade</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Software Upgrade</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Advance Security Assistance &amp; Support</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Data Encryption &amp; Tunneling</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Technical Documentation and Support</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Credential Protection and Password Handling</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Dedicated, Virtual, Cloud Setup</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Deployment Assistance</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Migration and Clone `</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Disaster Recovery and DR Drill</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Continuous Integration &amp; Delivery</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Custom Configuration &amp; Settings</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Replication Setup</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Custom &amp; Dedicated Solution</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Staffing Solution and Dedicated Resource</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Security and System Audits</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Additional Backup Configuration</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>High Availability and Clustering</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                                <tr>
                                    <td>Testing &amp; Training</td>
                                    <td>Hourly</td>
                                    <td>According to Contract</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="clearfix">
                            <p>X means this services included in the base package of managed services.</p>
                            <p>There will be two hours daily for DDevOps Services for high level task, any additional hour(s) will charge accordingly. Please check your Ecommerce Operations and DDevOps Services contract for subscribe features & hourly charges, any additional Support/Service will be charged separately on hourly rate.</p>
                        </div>

                        <div class="clearfix">
                            <h2>Services outside of the Scope</h2>
                            <p>Services outside of the scope of this Service Level Agreement are considered optional and will be billed at DDevOps Hourly rate.</p>
                            <p>These services may include, but are not limited to:</p>
                            <ul>
                                <li>Any technical troubleshooting on Server handled by Technical Team.</li>
                                <li>Setup & Configuration of any unsupported services.</li>
                                <li>Installation, configuration and/or troubleshooting of any unsupported software & applications.</li>
                                <li>Vendor assistance for third party tools.</li>
                            </ul>
                            <p>Services can be designed to meet specific business requirements in DDevOps Services. For more information or to setup an appointment to discuss custom management or services outside of scope, please contact <a href="mailto:support@ddevops.com">Support@DDevOps.com</a><br />
                                Additionally, DDevOps can provide Technology Consultation to assist different departments in planning for their infrastructure, software, hardware, and security needs.</p>
                        </div>

                        <div class="clearfix">
                            <h2>Backup Liability</h2>
                            <p>DDevOps provides automatic scheduled backups, but these systems do not replace the need for offsite backups for disaster recovery. DDevOps recommends backup offsite and at regular intervals for best data recovery.</p>
                            <ul>
                                <li>DDevOps provide automatic scheduled backups on servers that include Configuration, Web files &amp; folders along with databases. On/Off site Backup is recommended with minimum retention policy defined above in chart. DDevOps can support frequent retention policy and alternative backup solutions as per client requirement.</li>
                                <li>Local backup to hard drive may not be sufficient to prevent data loss, backup systems that do not include the saving of an offsite copy are not considered adequate for disaster recovery.</li>
                                <li>Backups does not guarantee that all data can be recovered to its last known state (depends on retention policy). DDevOps commits to protecting the client’s server supported under this SLA from deliberate damage.</li>
                                <li>DDevOps will not be held liable for any hardware damage to equipment owned by the client or infrastructure provider, or for data loss that occurs as a result of accidental actions by personnel either side. If an incident happens, DDevOps commits to providing recovery support as long as the system has some sort of backup. However, backup does not guarantee complete recovery or data restoration to last known state.</li>
                                <li>Each member of DDevOps will be responsible for proactively and reactively protect the information, resources and assets that client has put in trust of DDevOps, including determining when a risk may not be acceptable, and informing the appropriate parties.</li>
                                <li>Each server supported by DDevOps will be evaluated for risk at least once quarterly or on client request. In the event, if any security risk found, DDevOps will notify the client and set up a time to mitigate the risk.</li>
                            </ul>
                        </div>

                        <div class="clearfix">
                            <h2>Roles and Responsibilities</h2>
                            <ul>
                                <li><strong>DDevOps Responsibilities:</strong>
                                    <ul>
                                        <li>Meeting response times associated with service-related incidents or requests.</li>
                                        <li>Notifying customer of any maintenance, outage, or changes to the service that might affect their operations.</li>
                                        <li>Implementing defined processes for the best possible assistance and service delivery.</li>
                                        <li>Providing appropriate response and communication to service requests.</li>
                                        <li>Ensuring secure system configurations and compliance with industry best practices.</li>
                                        <li>Providing trained and professional staff for service delivery.</li>
                                        <li>Providing secure methods of communication for all service interactions.</li>
                                    </ul>
                                </li>
                                <li><strong>Customer Responsibilities:</strong>
                                    <ul>
                                        <li>Ensuring availability during service incidents or requests to provide information or resources needed to resolve issues.</li>
                                        <li>Submitting service requests through the appropriate channels as outlined in the agreement.</li>
                                        <li>Making prompt payments for all licenses, services, and additional charges incurred.</li>
                                        <li>Ensuring that critical data is saved in backed-up locations and that backup policies are adhered to.</li>
                                        <li>Notifying DDevOps of changes to user accounts, access needs, or other critical information.</li>
                                        <li>Allowing DDevOps to install and configure remote assistance tools when necessary for service delivery.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="clearfix">
                            <h2>Contact Information</h2>
                            <ul>
                                <li><strong>Support Email &amp; Ticketing System:</strong>
                                    <ul>
                                        <li>Primary method of contact: <a href="mailto:support@DDevOps.com">Support@DDevOps.com</a></li>
                                        <li>Helpdesk: <a href="http://support.ddevops.com">http://Support.DDevOps.com/</a></li>
                                    </ul>
                                </li>
                                <li><strong>Chat:</strong>
                                    <ul>
                                        <li>Available during normal hours of operation</li>
                                    </ul>
                                </li>
                                <li><strong>Account Owner:</strong>
                                    <ul>
                                        <li>Provided email address for Ecommerce Operation or DDevOps Services</li>
                                    </ul>
                                </li>
                                <li><strong>DDevOps Management:</strong>
                                    <ul>
                                        <li><a href="mailto:teamlead@ddevops.com">teamlead@ddevops.com</a></li>
                                    </ul>
                                </li>
                                <li><strong>Emergency Contact:</strong>
                                    <ul>
                                        <li>Phone number for urgent matters</li>
                                    </ul>
                                </li>
                                <li><strong>Non-Subscribers:</strong>
                                    <ul>
                                        <li>Contact via website or <a href="mailto:info@ddevops.com">Info@DDevOps.com</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="clearfix">
                            <h2>Operations Hours, Response Times & Escalation</h2>
                            <ul>
                                <li><strong>Operations Hours:</strong>
                                    <ul>
                                        <li>7 a.m. to 7 p.m. GMT, Monday to Friday (excluding Federal holidays)</li>
                                        <li>Technical support available for urgent matters</li>
                                    </ul>
                                </li>
                                <li><strong>Incident Response Times:</strong>
                                    <ul>
                                        <li>Urgent: 0-30 minutes initial response, 0-1 hour initiation of support</li>
                                        <li>High: 1 hour initial response, 1-2 hours initiation of support</li>
                                        <li>Normal: 3 hours initial response, 3-6 hours support (or as scheduled)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsOfService;
