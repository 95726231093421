import React from 'react'
import Slider from 'react-slick'
import "./developslickcard.css";
import CMS from "../../assets/image/developcards/cms.png";
import Eccomerce from "../../assets/image/developcards/eccomerce.png";
import Framework from "../../assets/image/developcards/framework.png";
import Php from "../../assets/image/developcards/php.png";
import Python from "../../assets/image/developcards/python.png";
import Java from "../../assets/image/developcards/java.png";
import Html from "../../assets/image/developcards/html.png";
import Javasript from "../../assets/image/developcards/javascript.png";
import Bash from "../../assets/image/developcards/bash.png";



function DevelopSlickCard() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '150px',
        responsive: [
            {
              breakpoint: 1250,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '100px',
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
              }
            },
            
          ]
    };

  return (
    <>
<div className="custom-container">
    <Slider className="service_carousel" {...settings}>
    <div className="service_item slick-card-border p-4 text-center">
    <img src={CMS} alt="icon" className="slick-img" />
        <h5 className="mt-4">CMS</h5>
        <p >Secure, scalable, modular, fully featured and built for high-performance,
            with flexibility to build better website with open source content management system.
           </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Eccomerce} alt="icon" className="slick-img" />
    <h5 className="mt-4">
    Ecommerce
      </h5>
        <p >Looking for best ecommerce developers? Experienced and 
            certified developers helps to build fully featured and perfect 
            store for your business.
           </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Framework} alt="icon" className="slick-img" />
    <h5 className="mt-4">Frameworks</h5>
        <p>
        Get development support for top frameworks built on the top of PHP, JAVA, and Python.
        Our experts are ready to deliver fully customize applications.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Php} alt="icon" className="slick-img" />
    <h5 className="mt-4">PHP</h5>
        <p >We mainly offer software development and consultancy for PHP, 
            and we got more experts help you to build great application in affordable cost.
           </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Python} alt="icon" className="slick-img" />
    <h5 className="mt-4">Python</h5>
        <p>
        Our experienced engineers and developer work together to delivers 
        complex web application and mobile solutions built on python.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Java} alt="icon" className="slick-img" />
    <h5 className="mt-4">JAVA</h5>
        <p>
        Our experts are ready to implement cutting-edge JAVA development solutions, 
        we offer different type of development and services for JAVA.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Html} alt="icon" className="slick-img" />
    <h5 className="mt-4">HTML/CSS</h5>
        <p>
        Design and define the structure of your web with our expert using HTML and CSS. 
        Together, we make up the essential building blocks of your websites.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Javasript} alt="icon" className="slick-img" />
    <h5 className="mt-4">JavaScript</h5>
        <p>
        The most popular programming language for interactive websites; 
        "virtually" everyone is using it extensively and to extend its functionality for you.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Bash} alt="icon" className="slick-img" />
    <h5 className="mt-4">Bash/Shell</h5>
        <p>
        We are ready to develop your web/app using AngularJS, HTML, MongoDB, 
        Node or Express JS, it’s time to experience greater development and best operations.
        </p>
    </div>
</Slider>
    </div>
    </>
  )
}

export default DevelopSlickCard
