// src/components/FlipCard.js

import React from 'react';
import './style.css';

const FlipCard = ({ title, frontDescription, backBgImage }) => {
    return (
        <div className="col-lg col-sm px-0 flip-card">
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <div className="card-content">
                        <h4>{title}</h4>
                        <p>{frontDescription}</p>
                    </div>
                </div>
                <div className="flip-card-back" style={{ backgroundImage: `url(${backBgImage})` }}>
                    <div className="card-content">
                        <h2>{title}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlipCard;
