import React from "react";
import { Icon } from '@iconify/react';
import "./topbar.css";

function Topbar() {
  return (
    <div className="container-fluid bg-dark">
      <div className="container">
        <div className="row " style={{ height: "28px" }}>
          <div
            className="col-md-6 text-light text-center mt-1 "
            style={{ fontSize: "12px" }}
          >
            <strong>Now Hiring </strong>: Are you a driven and motivated 1st Line DevOps Support
            Engineer?
          </div>

          <div className="col-md-2 text-light "></div>
          <div
            className="col-md-2 text-light mt-1"
            style={{ fontSize: "12px" }}
          >
            <a href="mailto:support@ddevops.com" class="text-reset footer-link footer-hover">
              <Icon icon="ant-design:mail-filled" color="white" width="20" height="20" className="me-2" />
              Support@ddevops.com
            </a>
          </div>
          <div
            className="col-md-2 text-light mt-1"
            style={{ fontSize: "12px" }}
          >
            <a href="https://dashboard.ddevops.com/login/" class="text-reset footer-link footer-hover">
              <Icon icon="carbon:logout" color="white" width="20" height="20" className="me-2" />
              Customer Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
