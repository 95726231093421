import React from "react";
import "./services.css";
import Service from "../../assets/image/1x/illustration-3.png";
import { Fade } from "react-reveal";

function Services() {
  return (
    <div>
    <div className="container-fluid logo-service">
      <div className="container logo-img-size400">
        <div className="row">
          <div className="col-lg-12 mt-5 mb-5 text-center heading-service">
            <h1 className="">
              <span className="service-span">DevOps</span> Service
            </h1>
            <h6>
              <span className="service-span">DDevOps</span> offers a wide range
              of IT services to handle operational requirements along with{" "}
              <br />
              CICD to keep you on a continuous path of advancement.{" "}
            </h6>
          </div>
        </div>
      </div>
      </div>

      <div>
        <div className="container">
          <div className="row">
            
            <Fade left>
              <div className="col-lg-6 p-5 heading-service watermark-img">
                <h6 className="heading-text ms-5" align="left">
                  We offer a complete process that can be put into <br />
                  place and repeats without distraction, either its a <br /> startup, complex infrastructure, enterprise <br />
                  application, and/or large e-commerce store.
                </h6>
              </div>
            </Fade>

            <Fade right>
              <div className="col-lg-6 img-center img-center375 img-center428">
                <img src={Service} alt="dm" width="70%" className="" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
