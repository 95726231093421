import React from "react";
import "./develop.css";
import Ecommerce from "assets/image/png/eccomerce.png";
// import Istock from "assets/image/png/right.png";
// import Verification from "assets/image/png/verification.png";
// import Devmobile from "assets/image/png/right2.png";

import DevelopCard from "Components/DevelopCards";

import DevelopTabs from "Components/DevelopTabs";
import DevelopSlickCard from "Components/DevelopSilkCard";

import Marquee from "react-fast-marquee";


import AnimatedText from 'react-animated-text-content';

import Button from "react-bootstrap/Button";

// Marquee Img

import Marqu1 from "assets/image/marquee/client1.avif";
import Marqu2 from "assets/image/marquee/client2.avif";
import Marqu3 from "assets/image/marquee/client3.avif";
import Marqu4 from "assets/image/marquee/client4.avif";
import Marqu5 from "assets/image/marquee/client5.avif";
import Marqu6 from "assets/image/marquee/client6.avif";
import Marqu7 from "assets/image/marquee/client7.avif";
import Brainstroming from "assets/image/deploycard/brainstrome.png";
import Plan from "assets/image/deploycard/planing.png";
import Design from "assets/image/deploycard/design.png";
import Deploy from "assets/image/deploycard/deploy.png";
import MetaTags from 'react-meta-tags';
import WpDevelopment from "assets/image/wp-development.png";

function Develop() {

  return (

    <>
      <MetaTags>
        <title>Dedicated DevOps | Design and Develop A Perfect Application</title>
        <meta name="description" content="Design and Develop A Perfect Application | Web And App Development Packed With Ultimate Features Of Your Choice." />
        <meta name="keywords" content="Design and Develop, Dedicated DevOps Services, DevOps Services, DevSecOps Services, Perfect Application, DDevOps" />
      </MetaTags>
      <div className="develop-gradiant">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-12 text-center">
                <AnimatedText className="f_d d_700 d_size_50 d_color d_height50 md_20"
                  type="chars" // animate words or chars
                  animation={{
                    x: '200px',
                    y: '-20px',
                    scale: 10,
                    ease: 'ease-in-out',
                  }}
                  animationType="rifle"
                  interval={0.06}
                  duration={0.8}
                  tag="p"
                  includeWhiteSpaces
                  threshold={0.1}
                  rootMargin="20%"
                >
                  Design & Build
                  A Perfect Applications

                </AnimatedText>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="mt-5">
        <DevelopTabs />
      </div>

      <div className="container ">
        <div className="row ">

        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12">
            <h1 className="text-center"><span className="develop-tab-color">HOW WE WORK</span><br />IMPLEMENTATION</h1>
          </div>
        </div>

        <div className="row mt-5 develop-card-responsive">

          <div className="col-lg-3 text-center">

            <img src={Brainstroming} alt="deploy" className="develoy-step" />

          </div>
          <div className="col-lg-3 text-center">

            <img src={Plan} alt="deploy" className="develoy-step" />

          </div>
          <div className="col-lg-3 text-center">

            <img src={Design} alt="deploy" className="develoy-step" />

          </div>
          <div className="col-lg-3 text-center">

            <img src={Deploy} alt="deploy" className="develoy-step" />

          </div>
        </div>

      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <h1>Eccommerce <span className="develop-span"> Solution</span></h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 button-margin">
            <p className="eccommerce-text">
              DDevOps e-commerce solution helps startups to grow faster and
              growing brands to grow even faster, nearly up to twice as fast as
              the industry standard. We help businesses moving online, empowered
              startups with DevOps methodologies like CI/CD that aid in the
              longer run, and growing brands with technology-focused solutions
              for continuous deployment, development, and operations for
              continuous advancement so choose us for our market-leading tools,
              enterprise-grade hosting and uptime, and stunning support.
            </p>
            <a href="/Contact">
              <Button className="develop-page-button ">
                Consult Now
              </Button>
            </a>
          </div>

          <div className="col-lg-6 text-center">
            <img src={Ecommerce} alt="eccomerce" className="eccomerce-responsive" />
          </div>
        </div>
      </div>

      <div className="everyone-online">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Let's Get <span className="develop-span">Everyone Online</span></h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 button-margin">
              <h4><span className="develop-span">Reliable, Responsive</span> & <span className="develop-span">Progressive Websites</span> For A Year</h4>
              <p className="eccommerce-text">
                DDevOps is committed to supporting communities during these challenging economic times by offering affordable web services that enable connectivity and technological accessibility for less than a dollar per day.
                <br /><br />
                <strong>Maximize Your Business Relation with Scalable And High-Performing WordPress Development Services!
                </strong><br />
                Along with <span className="develop-span">1 Year</span> Operation!!<br />
                AS LOW AS <span className="develop-span">$300</span>.
              </p>
            </div>
          </div>
        </div>
        <p>
          <a href="/Contact" style={{ position: 'relative' }}><img src={WpDevelopment} style={{ width: '190px', position: 'absolute', top: '-25px', left: '50px' }} /></a>
        </p>
      </div>


      <div>
        <DevelopCard />
      </div>


      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-lg-12 text-center">
            <h1>WEB <span className="develop-span">SERVICES</span></h1>
          </div>
        </div>
        <div>
          <DevelopSlickCard />
        </div>

      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <h1>OUR <span className="develop-span">EXPERTISE</span></h1>
          </div>
        </div>

        <div>
          <Marquee speed={20}>
            <img src={Marqu1} alt="m1" className="m-3" />
            <img src={Marqu2} alt="m2" className="m-3" />
            <img src={Marqu3} alt="m3" className="m-3" />
            <img src={Marqu4} alt="m4" className="m-3" />
            <img src={Marqu5} alt="m5" className="m-3" />
            <img src={Marqu6} alt="m6" className="m-3" />
            <img src={Marqu7} alt="m7" className="m-3" />
          </Marquee>
        </div>
      </div>
    </>
  );
}

export default Develop;
