import React from 'react'
import "./devops-services.css";
import Tab from "Components/Tab";
import ServicesExperience1 from "Components/DevopsWorkexperience";
import ServicesCard from "Components/ServicePrice";
import Testimonials2 from "Components/Testimonials2";
import Marquee2 from "../../Components/CompanyMarquee";
import MetaTags from 'react-meta-tags';
import { Icon } from '@iconify/react';
import devopsBanner from "../../assets/image/devops-bg.jpg";
import devopsBannerSecond from "../../assets/image/cover-6.webp";
import { GiCheckMark, GiHiveMind } from "react-icons/gi";


function DevOpsServices() {
  return (
    <>
      <MetaTags>
        <title>Dedicated DevOps Service | Dedicated DevOps Service</title>
        <meta name="description" content="Dedicated DevOps Service offers a wide range of IT solutions to handle operational requirements through DevOps Methodology." />
        <meta name="keywords" content="Dedicated DevOps Services, DevOps Services, DevSecOps Services, DevOps Solution, DDevOps" />
      </MetaTags>
      <div className="devops-gradiant">
        <img src={devopsBanner} />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt-5">
            <h3 className="heading-devops-services">
              <mark className="mark15">50+</mark>YEARS
              <br /> OF
              <br /> IT OPERATIONS
            </h3>
            <h1 className='mt-5 envolved-margin'>Envolved into <br /><span className='company-span'>DevOps</span></h1>
          </div>
          <div className="col-lg-6 mt-5">
            <div className="hr2"></div>
            <h4 className="devops-service-font12">
              DDevOps offers a wide range of IT services to handle operational requirements
              along with CICD to keep you on a continuous path of advancement.
            </h4>
            <p className='offer-margin'>We offer a complete process that can be put into place and repeats without distraction,
              either its a startup, complex infrastructure,
              enterprise application, and/or large e-commerce store.</p>
          </div>
        </div>
      </div>

      <div>
        <ServicesExperience1 />
      </div>

      <div>
        <Tab />
      </div>

      <div className='container how-we-claim'>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center m-4">
              <h1 className="tabs-font-size">
                How we claim to excel?
              </h1>
            </div>
          </div>
        </div>
        <div className="row content">
          <div className="col-lg col-sm-2 first">
            <Icon icon="ph:clock-counter-clockwise-thin" width="64" height="64" />
            <h5>01</h5>
            <h4> Quick response </h4>
            <p>We are not only available 24/7/365 but we impressed you with our 30 response times.</p>
          </div>
          <div className="col-lg col-sm-2 second">
            <Icon icon="fa:life-bouy" width="64" height="64" />
            <h5>02</h5>
            <h4> Experience experts</h4>
            <p>We got experience IT professionals with up to 18 years of technical excellence.</p>
          </div>
          <div className="col-lg col-sm-2 third">
            <Icon icon="fontisto:world-o" width="64" height="64" />
            <h5>03</h5>
            <h4> No geek speak </h4>
            <p>We provide technology services but our tools make things simple and human-readable.</p>
          </div>
          <div className="col-lg col-sm-2 fourth">
            <Icon icon="ph:briefcase" width="64" height="64" />
            <h5>04</h5>
            <h4> Business savvy </h4>
            <p>Strong acumen and expertise in IT business and provide expert consultants.</p>
          </div>
          <div className="col-lg col-sm-2 fifth">
            <Icon icon="simple-line-icons:lock" width="64" height="64" />
            <h5>05</h5>
            <h4> Guaranteed satisfaction </h4>
            <p>Our experienced professionals giving guaranteed satisfaction to our clients.</p>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row busniness-text-400">
          <div className="col-lg-12 text-center mt-5">
            <h1 className="business-font">
              Prominent DevOps Services
            </h1>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Collaboration
            </h5>
            <p className="business-p">
              Our IT operations work with the best collaboration practice to play significant roles in the development of the application lifecycle.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Automation
            </h5>
            <p className="business-p">
              We automate the complete delivery cycle and ensure that your deployments and rollbacks are performed in a click to minimize risks, increase diversity, and productivity.

            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Continuous Monitoring
            </h5>
            <p className="business-p">
              Continuous monitoring detects bugs & issues quickly when it occurs, or even before it affects your infrastructure or whole application.

            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Continuous Testing

            </h5>
            <p className="business-p">
              The continuous testing environment helps developers in balancing the quality and speed along with the error-free code delivery.

            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Continuous Integration

            </h5>
            <p className="business-p">
              The DevOps team follows best practices and uses different tools with a collaborative approach to support continuous application integration.

            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Continuous Delivery

            </h5>
            <p className="business-p">
              Continuous delivery is all about leveraging the concept of continuous integration to a whole new level giving you the results with less complexity.

            </p>
          </div>
        </div>
      </div>

      <div class="toppsbanner mt-5 my-2">
        <div class="toppsbanner-content">
          <h4>Top Providers + Top Services</h4>
          <span>Certified Dedicated DevOps Engineers</span>
        </div>
      </div>


      <ServicesCard />

      <div>
        <Testimonials2 />
      </div>

      <div>
        <Marquee2 />
      </div>
    </>
  )
}

export default DevOpsServices