import React from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import WebDesign from "assets/image/design-tabs/web-Design.png";
import WebDev from "assets/image/design-tabs/Web-Devep.png";
import MobileApp from "assets/image/design-tabs/Mobile-Apps.png";

import "./developtab.css";

function DevelopTab() {
  return (
    <>
      <div className="container-fliud ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center m-4">
                <h1 className="develop-tabs-font-size">
                  <span className="develop-tab-color">WEB AND APP DEVELOPMENT</span><br />PACKED WITH ULTIMATE FEATURES
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 ">
        <Tabs
          defaultActiveKey="WEB DESIGN"
          className="mb-3 develop-tabs-control d-flex tabs-responsive "
          fill
        >
          <Tab eventKey="WEB DESIGN" title="WEB DESIGN" tabClassName="develop-myclass develop-key-color">
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="develop-tabs-font-size">
                      The web design agency you select needs to understand all aspects of site creation.
                      This could be an enterprise or a brand, e-commerce with thousands of products,
                      few static pages, or even just a “brochure” use for a company introduction so it doesn’t matter for us
                      how big or small design you want us to make, all elements valued a lot and we make sure that
                      your customer sees and interacts easily with slick design and comfor
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={WebDesign} alt="php" className="server2 image-responsive" />
                  </div>

                </div>

              </div>

            </div>
          </Tab>
          <Tab
            eventKey="WEB DEVELOPMENT"
            title="WEB DEVELOPMENT"
            tabClassName="develop-myclass develop-key-color"
          >
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="develop-tabs-font-size">
                      A traditional web agency usually have the expertise to build – stylish,
                      static or dynamic contemporary CMS with common practices,
                      but we focused more on technology to address complex scenarios and security,
                      either it’s your e-commerce that includes various Platforms or CMS,
                      like WordPress, Magento, Joomla, Drupal etc. or a complete customize solution,
                      all great sites have one thing in common – technology focused plan from start to finish.
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={WebDev} alt="php" className="server2 image-responsive" />
                  </div>

                </div>

              </div>

            </div>
          </Tab>
          <Tab
            eventKey="MOBILE APPS"
            title="MOBILE APPS"
            tabClassName="develop-myclass develop-key-color"
          >
            <div>
              <div className="container">

                <div className="row">

                  <div className="col-lg-6 p-5 mt-5">
                    <p className="develop-tabs-font-size">
                      Do you want something simply elegant? like a restaurant menu,
                      a mobile portal for business or educational apps or a mobile version of
                      your current site? Maybe it’s something more casual,
                      like local events and festival? The most wonderful thing about the
                      mobile app is that you can make anything you want and we will help to get this done.
                      We not only take your ideas to create something innovative but do
                      offer a few based on your business for more innovation.
                      all elements valued a lot and we make sure that your customer sees
                      and interacts easily with slick design and comfort.
                    </p>

                  </div>

                  <div className="col-lg-6 text-center">
                    <img src={MobileApp} alt="php" className="server2 image-responsive" />
                  </div>

                </div>

              </div>

            </div>
          </Tab>

        </Tabs>
      </div>
    </>
  );
}

export default DevelopTab;
