import React from 'react'
import './test.css'


import Card from "react-bootstrap/Card";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';

import Automation from "../../assets/image/card/automation.png";
import Collaboration from "../../assets/image/card/collaboration.png";
import Testing from "../../assets/image/card/testing.png";
import Monitoring from "../../assets/image/card/monitoring.png";
import Integration from "../../assets/image/card/integration.png";
import Delivery from "../../assets/image/card/delivery.png";


function Testcard() {
  return (
    
    <div className='container'>

<Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={true} arrows={false}
    className="container d-flex justify-content-center"
     responsive={[{
  breakpoint: 800,
  settings: {
    slidesToShow: 3,
    slidesToScroll: 3
  }
}, {
  breakpoint: 500,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1
  }
}]} >


    <div className='test-card-margin card-responsive400 '>
    <div className='card-responsive360 '>
    <div className='card-responsive375 '>
    <div className='card-responsive-414'>
    <div className='card-responsive-1440'>
    
      <Card border="light" className="test-card card-animation1 m-2 ">
          <Card.Body className='p-4' >
          
            <div className='mb-2'>
            {/* <FcAutomatic size={100} /> */}
            <img src={Automation} alt="icon" className="card-icon card-icon-animation" />
            </div>
       
            <Card.Title className='card-title-margin'>Automation</Card.Title>
            <Card.Text>
            Automate the complete life cycle of your application
            to perform your deployments and rollbacks in a click to minimize risks, 
            increase diversity, and productivity.
            </Card.Text>
          </Card.Body>
        </Card>
        </div>
        </div>
        </div>
        </div>
        </div>

        <div className='test-card-coll card-responsive400'>
        <div className='card-responsive360 '>
        <div className='card-responsive375'>
        <div className='card-responsive-414'>
    <div className='card-responsive-1440'>

       <Card border="light" className="test-card card-animation1 m-2 ">
          <Card.Body className='p-4' >
    
            <div className='mb-2'>
            {/* <FcCollaboration size={100}/> */}
            <img src={Collaboration} alt="icon" className="card-icon card-icon-animation2 " />

            </div>
   
            <Card.Title className='card-title-margin'>Collaboration</Card.Title>
            <Card.Text>
            Our IT operations work with best collaboration practice to play 
            significant roles in the development of the application lifecycle.
            </Card.Text>
          </Card.Body>
        </Card>
        </div>
        </div>
        </div>
        </div>
        </div>
 
    <div className='card-responsive400 card-responsive428'>
    <div className='card-responsive360 '>
    <div className='card-responsive375 '>
    <div className='card-responsive-414'>
    <div className='card-responsive-1440'>

      <Card border="light" className="test-card card-animation1 m-2 ">
          <Card.Body className='p-4' >
     
            <div className='mb-2'>
            {/* <FcManager size={100}/> */}
            <img src={Monitoring} alt="icon" className="card-icon card-icon-animation3" />

            </div>
      
            <Card.Title className='card-title-margin' >Continuous Monitoring</Card.Title>
            <Card.Text>
            Continuous monitoring helps to detect bugs & issues quickly, 
            or even before it starts showing effects to your application or infrastructure.
            </Card.Text>
          </Card.Body>
        </Card>
        </div>
        </div>
        </div>
        </div>
        </div>
    
     <div className='test-card-cont card-responsive400'>
    <div className='card-responsive360 '>
     <div className='card-responsive375'>
     <div className='card-responsive-414'>
     <div className='card-responsive-1440'>

      <Card border="light" className="test-card card-animation1 m-2 ">
          <Card.Body className='p-4' >
        
            <div className='mb-2'>
            {/* <AiOutlineUnlock size={100} /> */}
            <img src={Testing} alt="icon" className="card-icon card-icon-animation" />

            </div>
       
            <Card.Title className='card-title-margin'>Continuous Testing</Card.Title>
            <Card.Text>
            The continuous testing environment helps developers in balancing 
            the quality and speed along with the error-free code delivery.
            </Card.Text>
          </Card.Body>
        </Card>
        </div>
        </div>
        </div>
        </div>
        </div>
        

        <div className='test-card-margin card-responsive400'>
    <div className='card-responsive360 '>
        <div className='card-responsive375 '>
     <div className='card-responsive-414'>
     <div className='card-responsive-1440'>

      <Card border="light" className="test-card card-animation1 m-2 ">
          <Card.Body className='p-4' >
          
            <div className='mb-2'>
            {/* <FcAutomatic size={100} /> */}
            <img src={Integration} alt="icon" className="card-icon card-icon-animation2" />

            </div>
       
            <Card.Title className='card-title-margin'>Continuous Integration</Card.Title>
            <Card.Text>
            Dedicated DevOps follows best practices and uses different
            tools with a collaborative approach to support continuous integration.
            </Card.Text>
          </Card.Body>
        </Card>
        </div>
        </div>
        </div>
        </div>
        </div>
  
        <div className='test-card-coll card-responsive400 '>
        <div className='card-responsive360'>
        <div className='card-responsive375'>
        <div className='card-responsive-414'>
        <div className='card-responsive-1440'>

       <Card border="light" className="test-card card-animation1 m-2 ">
          <Card.Body className='p-4' >
      
            <div className='mb-2'>
            {/* <FcCollaboration size={100} /> */}
            <img src={Delivery} alt="icon" className="card-icon card-icon-animation3" />

            </div>
       
            <Card.Title className='card-title-margin'>Continuous Delivery</Card.Title>
            <Card.Text>
            Continuous delivery is all about leveraging the concept of 
            continuous integration to the whole new level giving you the results with less complexity.
            </Card.Text>
          </Card.Body>
        </Card>
        </div>
        </div>
        </div>
        </div>
        </div>
 
    </Slide>
    </div>

  )
}

export default Testcard