import React from 'react'
import "./whitelabel.css";
import Zoom from 'react-reveal/Zoom';
import Featuresitems from '../../Components/Features/Featuresitems';
import { GiCheckMark } from 'react-icons/gi';
import MetaTags from 'react-meta-tags';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter'
import Infographics from "assets/image/inforgraphsics.png";
import Marquee2 from "../../Components/CompanyMarquee";
import whitelabelSolutionsBanner from "../../assets/image/whitelabel-solutions-bg.jpg";

function Whitelabel() {
  return (

    <>
      <div>
        <MetaTags>
          <title>White-label IT Solutions | DDevOps White-lable IT Solutions</title>
          <meta name="description" content="Dedicated DevOps specializes in technological managed IT services to provide state of the art white-label IT solutions." />
          <meta name="keywords" content="DevOps IT Solution, DevOps White-label, Dedicated DevOps IT Solution, Dedicated DevOps, DDevOps Services" />
        </MetaTags>
      </div>
      <div className="white-gradiant">
        <img src={whitelabelSolutionsBanner} />
      </div>

      <div className='container mb-5'>
        <div className='row mt-5 mb-5'>
          <div className='col-lg-12 text-center'>

            <h1>Whitelable Solution for <span className='whitelabel-span'>Information Technology</span></h1>

          </div>
        </div>

        <div className='row'>

          <div className='col-lg-5'>
            <div className="hr-whitelabel"></div>
            <h3 className='font-weight'>
              DDevOps specializes in <span className='whitelabel-span'>managed IT </span> services such as IT operations,
              system engineering, server management, cloud computing,
              infrastructure & network management, security assistance, etc.
            </h3>
          </div>

          <div className='col-lg-7'>
            <p className='white-p-size'>
              We help organizations implement and optimize their technical systems.
              Our white-label program typically takes place between technology vendors
              who provide IT products and the agencies and companies who want to enhance
              their daily operations capabilities through our skills employed to deliver the
              service (design, build, run) from your own branding. Technically,
              these fall into three main categories: business process services, application services,
              and infrastructure services.
            </p>
            <Link to="/Contact">
              <Button className='whitelabel-service-button text-light'>Talk to a consultant</Button>
            </Link>
            <Link to="/Contact">
              <Button className='whitelabel-service-button1'>Contact us now</Button>
            </Link>

          </div>


        </div>

      </div>

      <section className="process_area bg_color sec_pad">
        <div className="container">
          <h1 className='reliable-text'>Reliable Whitelable Solution for{' '}
            <span className='develop-span'>
              <Typewriter
                words={['Software Houses', 'Businesses', 'Agencies',]}
                loop={5}
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span></h1>
          <div className="features_info">
            <img className="dot_img" src={require('../../assets/image/home4/dividerwhite.png')} alt="" />
            <Zoom>
              <Featuresitems rowClass="row flex-row-reverse feature-text" aClass="pr_60 pl_60" fimage="Efficient.png" iImg="1.png" ftitle="Efficient Data Security" descriptions="During business transformations, there're possibilities for errors, but our highly advanced data management system spares no room for loss.!" />
              <Featuresitems rowClass="row feature-text2" aClass="pl_100" fimage="managment2.png" iImg="2.png" ftitle="Management Goals" descriptions="Our system is composed of currently most modern managing program, user-friendly interface and error-proof conduct methodology.!" />
              <Featuresitems rowClass="row flex-row-reverse feature-text3" aClass="pr_70 pl_70" fimage="multi1.png" iImg="3.png" ftitle="Reliable Multi-function"
                descriptions="You can totally count on DDevOps for all things related to information management, infrastructure design, automation, and other managed IT services.!" />
              {/* <Featuresitems rowClass="row feature-text4" aClass="pl_100" fimage="Teams.png" iImg="4.png" ftitle="Highly Professional Teams" 
                        descriptions="Having obtained the official & formal training in IT technology and technical fields, together, our staffs know more than what you might ever think of.!"/>
                        <Featuresitems rowClass="row flex-row-reverse feature-text5" aClass="pr_70 pl_70" fimage="integration.png" iImg="5.png" ftitle="Infrastructure Integration" 
                        descriptions="We have a holistic and integrated approach towards core modernization to experience technological evolution that makes it work with any infrastructure.!"/>
                        <Featuresitems rowClass="row feature-text6" aClass="pl_100" fimage="quality.png" iImg="6.png" ftitle="Quality Control System" 
                        descriptions="It's more than a responsibility, but a guarantee from us all at DDevOps Support Center to gain customer trust with highly reliable quality control system.!"/> */}
            </Zoom>
          </div>
        </div>
      </section>

      <div className='container'>
        <div className='row busniness-text-400'>
          <div className='col-lg-12 text-center mt-5'>
            <h1 className='business-font'>Business IT Support Features</h1>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> IT Design</h5>
            <p className='business-p'>We provide the most responsive and functional IT design for companies and businesses worldwide.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> IT Management</h5>
            <p className='business-p'>It’s possible to simultaneously manage and transform key information from one server to another.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Data Security</h5>
            <p className='business-p'>Back up your database, store in a safe and secure place while still maintaining its accessibility.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Business Reform</h5>
            <p className='business-p'>We propose feasible & practical plans for successfully transform businesses based on their needs.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Infrastructure Plan</h5>
            <p className='business-p'>Mitech takes into account all conditions and budgets needed for building infrastructure plan.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Firewall Advancement</h5>
            <p className='business-p'>Enhancing the strength and security of firewalls to protect online data from malicious sources.</p>
          </div>
        </div>
      </div>

      <div className="container resource-bg my-5">
        <div className="row">
          <div className="col-lg-6 button-margin">
            <h3 class="resource-heading">RESOURCES</h3>
            <h1 className=" main-heading ">
              <span className="heading-span">Get a copy of complete</span>
              <br />
              Service Description
            </h1>
            <Link to="/Contact">
              <Button className="btn button-background mt-3">
                Request a Copy
              </Button>
            </Link>
          </div>

          <div className="col-lg-6 text-center">
            <img src={Infographics} alt="eccomerce" className="eccomerce-responsive" width={450} height={350} />
          </div>


        </div>
      </div>
      <div>
        <Marquee2 />
      </div>
    </>
  )
}

export default Whitelabel