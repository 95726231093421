import React from "react";
import "./about-us.css";
import Testimonials2 from "Components/Testimonials2";
import AnimatedText from "react-animated-text-content";
import AboutServices from "Components/AboutServices";
import { GiCheckMark } from "react-icons/gi";

function aboutUs() {
  return (
    <>
      <div className="company-gradiant">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-12 text-center">
                <AnimatedText
                  className="f_ab ab_700 ab_size_50 ab_color ab_height50 mab_20"
                  type="chars" // animate words or chars
                  animation={{
                    x: "200px",
                    y: "-20px",
                    scale: 10,
                    ease: "ease-in-out",
                  }}
                  animationType="rifle"
                  interval={0.06}
                  duration={0.8}
                  tag="p"
                  includeWhiteSpaces
                  threshold={0.1}
                  rootMargin="20%"
                >
                  About Dedicated DevOps
                </AnimatedText>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 mb-5 f_ab text-center">
          <h2>One stop IT solutions for your Developments and Operations</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-5">
            <h3 className="heading-aboutus">
              <mark className="mark12">17</mark>YEARS’
              <br /> EXPERIENCE
              <br /> IN IT
              <br /> OPERATIONS
            </h3>
          </div>
          <div className="col-lg-6 mt-5">
            <div className="hr1"></div>
            <h4 className="tradional-font">
              Traditional IT operations changed in the past few years, gradually
              evolved into TechOps, DevOps, SecOps, and DevSecOps. Technically,
              all these evolutions bring IT solutions under one umbrella called
              Dedicated DevOps, where strong individuals building an invincible
              team.
            </h4>
          </div>
        </div>
      </div>
      <AboutServices />
      {/* <AgencyAbout ServiceData={ServiceData} /> */}

      <div className="container">
        <div className="row busniness-text-400">
          <div className="col-lg-12 text-center mt-5">
            <h1 className="business-font">
              We excel in delivering optimal performance.
            </h1>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Warranty Management IT
            </h5>
            <p className="business-p">
              Our service offerings enhance customer experience throughout
              secure & highly functional end-to-end warranty management.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Product Engineering & Services
            </h5>
            <p className="business-p">
              Develop and propose product improvements through periodical and
              accurate testing, repairing & refining every version.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Quality Control System
            </h5>
            <p className="business-p">
              It's more than a responsibility but a guarantee from us to gain
              customer trust with highly reliable quality control system.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Infrastructure Integration Technology
            </h5>
            <p className="business-p">
              At DDevOps, we have a holistic and integrated approach towards
              core modernization to experience technological evolution.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Highly Professional Staffs
            </h5>
            <p className="business-p">
              Having obtained the official & formal training in IT technology
              and technical fields, our staffs know more than what they show.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Information Security Management
            </h5>
            <p className="business-p">
              Information security has been a rising issue lately due to a
              series of scandals from big companies, rest assured, we're here.
            </p>
          </div>
        </div>
      </div>
      <Testimonials2 />
    </>
  );
}

export default aboutUs;
