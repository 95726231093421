import React from 'react'
import "./tesimonials2.css";
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';

import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem } from 'react-bootstrap';


function Testimonials2() {

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <section >

      <div className="container">
        <div className="row ">
          <div className="test1-gradiants test1-responsive-400 test1-responsive-428">
            <div className="test2-gradiants test2-responsive-400 test2-responsive-428">
              <div
                className="col-lg-12 mt-5 mb-5 text-center testi-gradiants testi-responsive-400 testi-responsive-428"
              >
                <br />
                <br />
                <h1>
                  Testimonials <br />{" "}
                  <span className="testi-span">Our Awesome Clients</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center p-5"><span className="testi-span"></span>
            <div className="testimonial_title">
              <Fade bottom cascade>
                <div className="seo_sec_title wow fadeInUp" data-wow-delay="0.3s">
                  <h2>Check <span className="testi-span">What</span> Our <br /><span className="testi-span">Customers</span> Are Saying</h2>
                  <p>You Can Relay On Our Amazing Features List And Also Our Customer Services Will Be Great Experience For You Without Doubt.!</p>
                </div>
              </Fade>

            </div>
          </div>
          <div className="col-lg-7 stratup_testimonial_info">
            <Carousel touch="true" indicators={false} nextIcon={false} prevIcon={false} >
              <CarouselItem interval={2000} >
                <div className="">
                  <Slider className="testimonial_slider_four" {...settings}>
                    <div className="item" >
                      <div className='text-margin'>
                        <p>Ddevops is one of the top hosting providers we started working with, we had some serious problems with our old host and the website started to became unstable but when we came to ddevops the services were hugely satisfied and their support is outstanding. We are very happy with the services and surely want to continue with them they are hugely recommended to anyone who is looking forward for a reliable hosting provider.</p>
                        <h4>Blue Ocean Restaurant</h4>
                        <p>Zohaib Ahmed</p>
                      </div>
                    </div>

                  </Slider>

                </div>
              </CarouselItem>

              <CarouselItem interval={2000}>
                <div className="">
                  <Slider className="testimonial_slider_four" {...settings}>
                    <div className="item" >
                      <div className='text-margin'>
                        <p>I was frustrated with my current infrastructure provider, however, I still need something that could help and support me technically, luckily one of my colleagues refer me to DDevOps. I did the meeting and as a result, their solution, pricing, and technical experiences urge me to gave them a chance and I got my expectations fulfilled. I can say that I am one of the happiest customers of DDevops and want to continue with them in the future, highly recommend others. Best of luck team!!
                        </p>
                        <h4>Design Intellect</h4>
                        <p>Behlool Fakhruddin</p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </CarouselItem>

              <CarouselItem interval={2000}>
                <div className="">
                  <Slider className="testimonial_slider_four" {...settings}>
                    <div className="item" >
                      <div className='text-margin'>
                        <p>When you are building a high-tech cloud based project there are a number challenges, one of them is to ensure a strong DevOps team. DDevOps offer exactly this kind of experience and expertise we seek with in our partner echo-system. Our global experience, blended with DDevOps AWS Cloud expertise ensure us to deliver the best breed of DevOps solutions to our high value customers globally.</p>
                        <h4>AVIANET</h4>
                        <p>Arshad Mughal - General Manager & CTO</p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </CarouselItem>


              <CarouselItem interval={2000}>
                <div className="">
                  <Slider className="testimonial_slider_four" {...settings}>
                    <div className="item" >
                      <div className='text-margin'>
                        <p>
                          A consumer internet business with a very delicate & complex IT infrastructure requires intelligence and expertise. We have invested a lot of time into building a team that can help develop the vision and found it difficult to have a partner that will provide the infrastructure needed to do so. This was our struggle for many years until we partnered up with DDevOps to manage our servers and IT infrastructure. I am very impressed with DDevOps availability from a support standpoint, they seem almost 24*7, so this is another reason we are super happy with services.
                        </p>
                        <h4>HOOJAN</h4>
                        <p>Saad Chkail - CEO</p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </CarouselItem>

              <CarouselItem interval={2000}>
                <div className="">
                  <Slider className="testimonial_slider_four" {...settings}>
                    <div className="item" >
                      <div className='text-margin'>
                        <p>
                          DDevOps is definitely one of the best solution providers for hosting services, I would rate their support as the best we have experienced, they were very quick to respond and we hardly faces any issues while working with them, most importantly their professional approach towards any request is next to none, we will definitely recommend them with high regards.                        </p>
                        <h4>Fakieheats</h4>
                        <p>Zohaib Ahmed - Vantage Digital</p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </CarouselItem>

              <CarouselItem interval={2000}>
                <div className="">
                  <Slider className="testimonial_slider_four" {...settings}>
                    <div className="item" >
                      <div className='text-margin'>
                        <p>
                          We were struggling for many years until we partnered up with DDevOps to manage our servers and IT infrastructure. Working with them is like having a family member who can do everything for you. I don’t know how but they know what you need, exactly when you need it. I am very impressed with DDevOps.</p>
                        <h4>GOPROT SARL</h4>
                        <p>Muhammad Talha - CTO</p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </CarouselItem>

            </Carousel>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials2