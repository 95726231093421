import React from 'react';

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Topbar from "Components/Topbar";
import Navbars from "Components/Navbar";
import Home from "pages/Home";
import Company from "pages/Company";
import AboutUs from "pages/About-us";
import Services from "pages/Services";
import DevopsServices from "pages/Devops-services";
import DevsecopsServices from "pages/DevsecopsServices";
import Whitelabel from "pages/Whitelabel-Solution";
import Develop from "pages/Develop";
import Partnership from "pages/Partnership";
import ContactUs from "pages/Contact";
import Blog from "pages/Blog";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/Terms-of-service";
import Footer from "Components/Footer";
import ScrollToTop from 'Components/ScrollToTop';
import NotFound from 'pages/NotFound';  // Import the NotFound component

function App() {
  return (
    <BrowserRouter>
      <Topbar />
      <Navbars />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Company" element={<Company />} />
        <Route path="AboutUS" element={<AboutUs />} />
        <Route path="Services" element={<Services />} />
        <Route path="DevopsServices" element={<DevopsServices />} />
        <Route path="DevsecopsService" element={<DevsecopsServices />} />
        <Route path="Whitelabel" element={<Whitelabel />} />
        <Route path="Develop" element={<Develop />} />
        <Route path="Partnership" element={<Partnership />} />
        <Route path="Contact" element={<ContactUs />} />
        <Route path="Blog" element={<Blog />} />
        <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="TermsOfService" element={<TermsOfService />} />

        {/* 404 Route - This should be the last route */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
