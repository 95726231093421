import React from 'react'
import AnimatedText from 'react-animated-text-content';
import "./blog.css";


function Blog() {
  return (
    <>
    <div className="blog-gradiant">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="col-lg-12 text-center">
          <AnimatedText className="f_blog blog_700 blog_size_50 blog_color blog_height50 blog_20"
              type="chars" // animate words or chars
              animation={{
                x: '200px',
                y: '-20px',
                scale: 10,
                ease: 'ease-in-out',
              }}
              animationType="rifle"
              interval={0.06}
              duration={0.8}
              tag="p"
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
            Blog
            </AnimatedText>
           
          </div>
        </div>
      </div>
    </div>

  </div>
        
    </>
  )
}

export default Blog