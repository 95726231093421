import React from 'react';
import "./partnership.css";
import Featuresitems from '../../Components/Features/Featuresitems';
import { GiCheckMark } from 'react-icons/gi';
import Zoom from 'react-reveal/Zoom';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Infographics from "assets/image/inforgraphsics.png";
import Marquee2 from "../../Components/CompanyMarquee";
import technologyPartnershipBanner from "../../assets/image/technology-partnership-bg.jpg";

const Process = () => {

    return (

        <div className="body_wrapper">
            <MetaTags>
                <title>DevOps Technology Partnership | Dedicated DevOps</title>
                <meta name="description" content="Dedicated DevOps Technology Partnership is for Technology Innovation as specializes in technological managed IT services." />
                <meta name="keywords" content="DevOps Technology, DevOps Partnership, Dedicated DevOps Technology, Dedicated DevOps Partnership, DDevOps Services" />
            </MetaTags>
            <div className="partnership-gradiant">
                <img src={technologyPartnershipBanner} />
            </div>

            <div className='container mb-5'>
                <div className='row mt-5 mb-5'>
                    <div className='col-lg-12 text-center'>
                        <h1>Technology Partnership for<span className="develop-span"> Technology Innovation</span></h1>
                    </div>
                </div>

                <div className='row'>

                    <div className='col-lg-5'>
                        <div className="hr-whitelabel"></div>
                        <h3 className='font-weight'>
                            Technology Partnership for <span className='whitelabel-span'>managed IT services </span>  such as IT operations, system engineering, server management, cloud computing, infrastructure & network management, security assistance, etc.
                        </h3>
                    </div>

                    <div className='col-lg-7'>
                        <p className='white-p-size'>
                            We help organizations implement and optimize their technical systems. Our partnerships typically take place between technology vendors who provide the product and the agencies and companies who want to enhance their daily operations capabilities through our skills employed to deliver the service (design, build, run) together. Technically, these fall into three main categories: business process services, application services, and infrastructure services.</p>

                        <Link to="/Contact">
                            <Button className='whitelabel-service-button text-light'>Let's Talk About Contract</Button>
                        </Link>
                        <Link to="/Contact"><Button className='whitelabel-service-button1 text-light'>Free Consultation</Button>
                        </Link>

                    </div>


                </div>

            </div>

            <section className="process_area bg_color sec_pad">
                <div className="container">
                    <div className="features_info">
                        <img className="dot_img" src={require('../../assets/image/home4/divider.png')} alt="" />
                        <Zoom>
                            <Featuresitems rowClass="row flex-row-reverse feature-text" aClass="pr_60 pl_60" fimage="Efficient.png" iImg="1.png" ftitle="Efficient Data Security" descriptions="During business transformations, there're possibilities for errors, but our highly advanced data management system spares no room for loss.!" />
                            <Featuresitems rowClass="row feature-text2" aClass="pl_100" fimage="managment2.png" iImg="2.png" ftitle="Management Goals" descriptions="Our system is composed of currently most modern managing program, user-friendly interface and error-proof conduct methodology.!" />
                            <Featuresitems rowClass="row flex-row-reverse feature-text3" aClass="pr_70 pl_70" fimage="multi1.png" iImg="3.png" ftitle="Reliable Multi-function"
                                descriptions="You can totally count on DDevOps for all things related to information management, infrastructure design, automation, and other managed IT services.!" />
                            <Featuresitems rowClass="row feature-text4" aClass="pl_100" fimage="Teams.png" iImg="4.png" ftitle="Highly Professional Teams"
                                descriptions="Having obtained the official & formal training in IT technology and technical fields, together, our staffs know more than what you might ever think of.!" />
                            <Featuresitems rowClass="row flex-row-reverse feature-text5" aClass="pr_70 pl_70" fimage="integration.png" iImg="5.png" ftitle="Infrastructure Integration"
                                descriptions="We have a holistic and integrated approach towards core modernization to experience technological evolution that makes it work with any infrastructure.!" />
                            <Featuresitems rowClass="row feature-text6" aClass="pl_100" fimage="quality.png" iImg="6.png" ftitle="Quality Control System"
                                descriptions="It's more than a responsibility, but a guarantee from us all at DDevOps Support Center to gain customer trust with highly reliable quality control system.!" />
                        </Zoom>
                        {/* <div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div> */}
                    </div>
                </div>
            </section>
            <div className='container'>
                <div className='row busniness-text-400'>
                    <div className='col-lg-12 text-center mt-5'>
                        <h1 className='business-font'>Business IT Support Features</h1>
                    </div>
                    <div className='col-lg-6 mt-4'>
                        <h5 className='span-color'><GiCheckMark /> IT Design</h5>
                        <p className='business-p'>We provide the most responsive and functional IT design for companies and businesses worldwide.</p>
                    </div>
                    <div className='col-lg-6 mt-4'>
                        <h5 className='span-color'><GiCheckMark /> IT Management</h5>
                        <p className='business-p'>It’s possible to simultaneously manage and transform key information from one server to another.</p>
                    </div>
                    <div className='col-lg-6 mt-4'>
                        <h5 className='span-color'><GiCheckMark /> Data Security</h5>
                        <p className='business-p'>Back up your database, store in a safe and secure place while still maintaining its accessibility.</p>
                    </div>
                    <div className='col-lg-6 mt-4'>
                        <h5 className='span-color'><GiCheckMark /> Business Reform</h5>
                        <p className='business-p'>We propose feasible & practical plans for successfully transform businesses based on their needs.</p>
                    </div>
                    <div className='col-lg-6 mt-4'>
                        <h5 className='span-color'><GiCheckMark /> Infrastructure Plan</h5>
                        <p className='business-p'>Mitech takes into account all conditions and budgets needed for building infrastructure plan.</p>
                    </div>
                    <div className='col-lg-6 mt-4'>
                        <h5 className='span-color'><GiCheckMark /> Firewall Advancement</h5>
                        <p className='business-p'>Enhancing the strength and security of firewalls to protect online data from malicious sources.</p>
                    </div>
                </div>
            </div>

            <div className="container resource-bg my-5">
                <div className="row">
                    <div className="col-lg-6 button-margin">
                        <h3 class="resource-heading">RESOURCES</h3>
                        <h1 className=" main-heading ">
                            <span className="heading-span">Get a copy of complete</span>
                            <br />
                            Service Description
                        </h1>
                        <Link to="/Contact">
                            <Button className="btn button-background mt-3">
                                Request a Copy
                            </Button>
                        </Link>
                    </div>

                    <div className="col-lg-6 text-center">
                        <img src={Infographics} alt="eccomerce" className="eccomerce-responsive" width={450} height={350} />
                    </div>


                </div>
            </div>

            <div>
                <Marquee2 />
            </div>
        </div>
    )
}
export default Process;