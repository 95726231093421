import React from 'react'
import Slider from 'react-slick'
import "./slickcard.css";
import Infrastructure from "../../assets/icons/Infrastructure.png";
import Monitoring from "../../assets/icons/Monitoring.png";
import Stack from "../../assets/icons/stack.png";
import Secure from "../../assets/icons/secure.png";
import Patches from "../../assets/icons/Patches.png";
import Security from "../../assets/icons/security.png";
import Multi from "../../assets/icons/multi.png";
import Application from "../../assets/icons/application.png";


function SlickCard() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '150px',
        responsive: [
            {
              breakpoint: 1250,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '100px',
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
              }
            },
            
          ]
    };

  return (
    <>
<div className="custom-container">
    <Slider className="service_carousel" {...settings}>
    <div className="service_item slick-card-border p-4 text-center">
    <img src={Infrastructure} alt="icon" className="slick-img" />
        <h5 className="mt-4">All Infrastructure Support</h5>
        <p >You can freely choose the Infrastructure of your choice, either it's Dedicated
           Server, VPS, or Cloud, we always available for experts advice.
           </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Monitoring} alt="icon" className="slick-img" />
    <h5 className="mt-4">
      Active Monitoring & Auto Healing
      </h5>
        <p >Dedicated DevOps provides real time monitoring along with
            auto healing that starts service automatically in case of
            a crash.
           </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Stack} alt="icon" className="slick-img" />
    <h5 className="mt-4">Custom Stacks & Faster Performance</h5>
        <p>
        DDevOps developed rapid fast stacks for PHP, 
        Java & MEAN that help to boost up to 90% of your 
        application performance.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Secure} alt="icon" className="slick-img" />
    <h5 className="mt-4">Secure Access to Server</h5>
        <p > We use only secure protocols along with a secure GUI to
             manage files, folders and databases, encryption keeps data
             protected server.
           </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Patches} alt="icon" className="slick-img" />
    <h5 className="mt-4"> Patches, Updates & Upgrades</h5>
        <p>
          Security is our top most priority, we kept all our servers
          up to data with latest security patches & sofrware Update,
          encourage our client.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Security} alt="icon" className="slick-img" />
    <h5 className="mt-4">Advance Security Assistance</h5>
        <p>
        If you are more concerned about internal 
        or external threads from the hackers, 
        attackers or even competitors, we offer 
        different value added services to 
        accommodate your needs.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Multi} alt="icon" className="slick-img" />
    <h5 className="mt-4">Multi-tier & High Availability</h5>
        <p>
        Our engineers help you to create multi-tier 
        high availability setups along with load 
        balancing to maximize the availability of your 
        online application, along with multi and geo-location capabilities.
        </p>
    </div>
    <div className="service_item p-4 text-center">
    <img src={Application} alt="icon" className="slick-img" />
    <h5 className="mt-4">Application Management</h5>
        <p>
        We know how to handle different CMS, 
        framework and core languages used to 
        create online applications, thus with 
        the development team we manage applications at best.
        </p>
    </div>
</Slider>
    </div>
    </>
  )
}

export default SlickCard
