import React, { Component } from 'react';
import "./price.css";

class SecurityPrice extends Component {
    constructor() {
        super();

        this.basicTitles = [
            { id: 1, title: "Server and Application Administration" },
            { id: 2, title: "Server & Application Monitoring" },
            { id: 3, title: "Alerts, Notifications & Basic Statistics" },
            { id: 4, title: "Security Updates & Patching" },
            { id: 5, title: "Configuration Assistance" },
            { id: 6, title: "Tweak & Optimization" },
            { id: 7, title: "Backup & Data Recovery" },
            { id: 8, title: "Migration Assistance" },
            { id: 9, title: "Versioning & Git Integrations" },
            { id: 10, title: "Emails & DNS Management" },
            { id: 11, title: "Multiple Environment Support" },
            { id: 12, title: "Multi-tier Steps and Support" },
            { id: 13, title: "Unlimited Support Tickets" },
            { id: 14, title: "Round the Clock Emergency assistance" }
        ];

        this.professionalTitles = [
            { id: 1, title: "All In Basic DevOps Services" },
            { id: 2, title: "Pro-active Monitoring & Professional Statistics" },
            { id: 3, title: "Premium Web-Stack (PHP, JAVA & MEAN/MERN)" },
            { id: 4, title: "Application & Server Optimization" },
            { id: 5, title: "Security Assistance & Support" },
            { id: 6, title: "Application upgrades & Support" },
            { id: 7, title: "Pro-Active Monitoring & Auto Healing" },
            { id: 8, title: "Server & Application Optimization" },
            { id: 9, title: "Multiple Infrastructure Support (Dedicated, VPS or Cloud)" },
            { id: 10, title: "Migration Assistance & DR Drills" },
            { id: 11, title: "CI/CD Configuration & Support" },
            { id: 12, title: "Dedicated Account Owner" },
            { id: 13, title: "Chat & Email Support or Ticketing System" },
            { id: 14, title: "Round the clock Assistance" }
        ];

        this.enterpriseTitles = [
            { id: 1, title: "All In Basic and Professional DevOps Services" },
            { id: 2, title: "Premium Support & Monitoring" },
            { id: 3, title: "CI/CD Pipeline for Deployment" },
            { id: 4, title: "Automation & Custom Scripting" },
            { id: 5, title: "Custom Configuration & Setups" },
            { id: 6, title: "Advance Security Assistance & Support" },
            { id: 7, title: "High Availability Setup and Support" },
            { id: 8, title: "Third Party Integration and Support" },
            { id: 9, title: "Workshops & Training" },
            { id: 10, title: "Security and System Audit" },
            { id: 11, title: "Staffing Solution and Dedicated Resource" },
            { id: 12, title: "Custom or Dedicated IT Solutions" },
            { id: 13, title: "Phone, Chat & Email Support or Ticketing System" },
            { id: 14, title: "Round the Clock assistance" }
        ];

    }

    render() {
        return (
            <section className="pricing_area_four sec_pad">
                <div className="container">
                    <div className="hosting_title text-center">
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">DDevOps Packages.</h2>
                    </div>
                    <div className="row price_info_two price_info_three">
                        {/* Basic Column */}
                        <div className="col-lg px-0">
                            <div className="price_head">
                                <div className="p_head">
                                    <h5>BASIC</h5>
                                    <p>Starts from<br />$339 / mo</p>
                                </div>
                            </div>
                            <div className="price_body">
                                {
                                    this.basicTitles.map(list => (
                                        <div className="pr_list" key={`basic-${list.id}`}>
                                            <div className="price_item">
                                                <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" >{list.title}</h5>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pr_list">
                                    <div className="price_item">
                                        <a href="/Contact" className="price_btn btn_hover">Choose This</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Professional Column */}
                        <div className="col-lg px-0">
                            <div className="price_head">
                                <div className="p_head pro">
                                    <h5>PROFESSIONAL</h5>
                                    <p>Starts from<br />$799 / mo</p>
                                </div>
                            </div>
                            <div className="price_body">
                                {
                                    this.professionalTitles.map(list => (
                                        <div className="pr_list" key={`professional-${list.id}`}>
                                            <div className="price_item">
                                                <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" >{list.title}</h5>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pr_list">
                                    <div className="price_item">
                                        <a href="/Contact" className="price_btn btn_hover">Choose This</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Enterprise Column */}
                        <div className="col-lg px-0">
                            <div className="price_head">
                                <div className="p_head enterprise">
                                    <h5>ENTERPRISE</h5>
                                    <p>Starts from<br />$1399 / mo</p>
                                </div>
                            </div>
                            <div className="price_body">
                                {
                                    this.enterpriseTitles.map(list => (
                                        <div className="pr_list" key={`enterprise-${list.id}`}>
                                            <div className="price_item">
                                                <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" >{list.title}</h5>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pr_list">
                                    <div className="price_item">
                                        <a href="/Contact" className="price_btn btn_hover">Choose This</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SecurityPrice;
