import React from 'react'
import Marquee from "react-fast-marquee";

import SonarQube from "../../assets/image/marquee2/sonar.avif";
import Terraform from "../../assets/image/marquee2/terraform.avif";
import Monit from "../../assets/image/marquee2/monit.avif";
import Docker from "../../assets/image/marquee2/docker.avif";
import Elk from "../../assets/image/marquee2/elk.avif";
import Jenkins from "../../assets/image/marquee2/jenkins.avif";
import Loader from "../../assets/image/marquee2/loader.avif";
import Splunk from "../../assets/image/marquee2/splunk.avif";
import Suporvisor from "../../assets/image/marquee2/supervisor.avif";



function MarqueePartnership() {
  return (
    <div className='container'>

<Marquee speed={10}>
  <img src={SonarQube} alt="sonar" className='m-3'  />
  <img src={Terraform} alt="terra" className='m-3' />
  <img src={Suporvisor} alt="kobernetes" className='m-3' />
  <img src={Monit} alt="monit" className='m-3'/>
  <img src={Elk} alt="monit" className='m-3'/>
  <img src={Jenkins} alt="monit" className='m-3'/>
  <img src={Loader} alt="monit" className='m-3'/>
  <img src={Docker} alt="monit" className='m-3'/>
  <img src={Splunk} alt="monit" className='m-3'/>

  
</Marquee>

    </div>
  )
}

export default MarqueePartnership