import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import "./footer.css";
import Logo1 from "assets/image/footer-logo-black.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Icon } from '@iconify/react';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="">
      <MDBFooter
        className="text-center text-lg-start text-muted footer-watermark "
      >
        <section className="d-flex justify-content-center justify-content-lg-between border-bottom">
          <div>

          </div>
        </section>

        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <p>
                  <a href="/">
                    <img src={Logo1} alt="logo" width={225} height={53} />
                  </a>
                </p>
                <p>
                  <Icon icon="la:mail-bulk" width="1.2rem" height="1.2rem" /> 2770 Coastal Bay DR Unit 105, Orange City, FL 32763.</p>
                <p><Icon icon="la:user-cog" width="1.2rem" height="1.2rem" /> Suite No. 41F Ground Floor, PECHS Block 2, Karachi.</p>
                <p><Icon icon="mdi:alternate-email" width="1.2rem" height="1.2rem" /> <a href="mailto:info@ddevops.com" class="text-reset footer-link footer-hover">info@ddevops.com</a></p>
                <p><Icon icon="iconoir:phone" width="1.2rem" height="1.2rem" /> <a href="tel:+13867489010" class="text-reset footer-link footer-hover">1 386 748 9010</a></p>
                <p><Icon icon="iconoir:phone-plus" width="1.2rem" height="1.2rem" /> <a href="tel:+923023338677" class="text-reset footer-link footer-hover">92 302 333 8677</a>
                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4 ">
                <h6 className="text-uppercase fw-bold mb-4 text-dark ">
                  Services
                </h6>
                <Link to="devopsservices" className="text-reset footer-link footer-hover">
                  <p>DevOps Services</p>
                </Link>
                <Link to="devsecopsservice" className="text-reset footer-link footer-hover">
                  <p>SecOps Services</p>
                </Link>
                <Link to="partnership" className="text-reset footer-link footer-hover">
                  <p>Technology partnership</p>
                </Link>
                <Link to="Whitelabel" className="text-reset footer-link footer-hover">
                  <p>Whitelable Solutions</p>
                </Link>

              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4 text-dark footer-hover">
                  Quick links
                </h6>
                <Link to="https://dashboard.ddevops.com/login" className="text-reset footer-link footer-hover">
                  <p>Customer Login</p>
                </Link>
                <Link to="PrivacyPolicy" className="text-reset footer-link footer-hover">
                  <p>Privacy Policy</p>
                </Link>
                <Link to="TermsOfService" className="text-reset footer-link footer-hover">
                  <p>Terms & Conditions</p>
                </Link>
                <Link to="https://dashboard.ddevops.com/register" className="text-reset footer-link footer-hover">
                  <p>Registration</p>
                </Link>

              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4 text-dark footer-hover">
                  Support
                </h6>
                <Link to="https://support.ddevops.com/hc/en-us" className="text-reset footer-link footer-hover">
                  <p>Help & FAQ</p>
                </Link>
                <Link to="https://support.ddevops.com" className="text-reset footer-link footer-hover">
                  <p>Support Ticket</p>
                </Link>
                <Link to="Contact" className="text-reset footer-link footer-hover">
                  <p>Contact Us</p>
                </Link>
                <Link to="https://discus.ddevops.com" className="text-reset footer-link footer-hover">
                  <p>Forum</p>
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>


        {/* <footer className=" new_footer_area bg_color">
                <div className="new_footer_top">
                    <div className="container">
                  
                        
                    </div>
                    <div className="footer_bg">
                        <div className="footer_bg_one"></div>
                        <div className="footer_bg_two"></div>
                    </div>
                </div>

                <div className="footer_bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-7">
                            </div>
                          
                        </div>
                    </div>
                </div>
            </footer> */}



        <div className="container">
          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  <p>
                    Copyright © {currentYear}<a href="https://ddevops.com/" target="_blank" rel="noreferrer" className="text-reset footer-link footer-hover"> DDevOps.com</a>
                  </p>
                </div>
              </div>

              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">

                <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip-top">Facebook</Tooltip>}>
                  <span className="d-inline-block" >
                    <div className="tooltip-btn">
                      <a className="btn btn-floating"
                        href="https://www.facebook.com/DedicatedDevOps/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon icon="fa-brands:facebook-square" width="40" height="40" />

                      </a>

                    </div>
                  </span>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip-top">Facebook</Tooltip>}>
                  <span className="d-inline-block" >
                    <div className="tooltip-btn">
                      <a className="btn btn-floating"
                        href="https://www.instagram.com/dedicateddevops/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon icon="fa-brands:instagram-square" width="40" height="40" />

                      </a>

                    </div>
                  </span>
                </OverlayTrigger>


                <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip-top">Twitter</Tooltip>}>
                  <span className="d-inline-block" >
                    <div className="tooltip-btn">
                      <a className="btn btn-floating"
                        href="https://twitter.com/officialddevops"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon icon="fa6-brands:square-x-twitter" width="40" height="40" />

                      </a>

                    </div>
                  </span>
                </OverlayTrigger>


                <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip-top">Whatsapp</Tooltip>}>
                  <span className="d-inline-block" >
                    <div className="tooltip-btn">
                      <a className="btn btn-floating"
                        href="https://wa.me/03023338677"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon icon="uil:whatsapp-alt" width="40" height="40" />

                      </a>

                    </div>
                  </span>
                </OverlayTrigger>


                <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip-top">Youtube</Tooltip>}>
                  <span className="d-inline-block" >
                    <div className="tooltip-btn">
                      <a className="btn btn-floating"
                        href="https://www.youtube.com/@DedicatedDevOps"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon icon="fa-brands:youtube-square" width="40" height="40" />

                      </a>

                    </div>
                  </span>
                </OverlayTrigger>


                <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip-top">Youtube</Tooltip>}>
                  <span className="d-inline-block" >
                    <div className="tooltip-btn">
                      <a className="btn btn-floating"
                        href="https://www.linkedin.com/company/dedicateddevops"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon icon="fa-brands:linkedin" width="40" height="40" />

                      </a>

                    </div>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </section>
        </div>
      </MDBFooter>

    </div>
  );
}

export default Footer;
