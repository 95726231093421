import SlickCard from 'Components/SlickCard'
import React from 'react'
import "./slickcardcall.css";


function Slickcardcall() {
  return (
    <div className=''>
            <section className="prototype_service_area_two">
            <div className="container">
                {/* <Sectitle Title="Your enterprise carrier in the Cloud" TitleP="WShow off show off pick your nose and blow off Elizabeth grub haggle dropped a clanger cracking.!"
                tClass="t_color3" sClass="sec_title text-center mb_70"/> */}
                <SlickCard/>
                </div>
            </section>
    </div>
  )
}

export default Slickcardcall