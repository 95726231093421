import React, { Component } from 'react';

class SecurityAddon extends Component {
    constructor() {
        super();
        this.basicTitles = [
            { id: 1, title: "Ultra-Fast Connection" },
            { id: 2, title: "Secure Connectivity" },
            { id: 3, title: "Data Encryption" },
            { id: 4, title: "Multiple Devices Setup" },
            { id: 5, title: "No-logs No-Restrictions policy" },
            { id: 6, title: "Offshore Privacy Protection" },
            { id: 7, title: "Virtual Private Network" },
            { id: 8, title: "Multiple Region" },
            { id: 9, title: "Dedicated IP Address" },
            { id: 10, title: "1Gbps Connection" },
            { id: 11, title: "24/7 Support" }
        ];

        this.professionalTitles = [
            { id: 1, title: "Secure and Cost-Effective" },
            { id: 2, title: "Multiple Geolocation Targets" },
            { id: 3, title: "Dynamic DNS" },
            { id: 4, title: "EDNS-client-subnet support" },
            { id: 5, title: "DDoS Protected DNS" },
            { id: 6, title: "100M Queries per month" },
            { id: 7, title: "Web Redirects" },
            { id: 8, title: "Search Engine Friendly" },
            { id: 9, title: "Flexible and Scalable" },
            { id: 10, title: "Statistics and DNS Branding" },
            { id: 11, title: "24/7 Support" }
        ];

        this.enterpriseTitles = [
            { id: 1, title: "Smart Application Security" },
            { id: 2, title: "Built-in DDOS mitigation" },
            { id: 3, title: "Global CDN Support" },
            { id: 4, title: "Image & Mobile optimization" },
            { id: 5, title: "Reporting and Analytics" },
            { id: 6, title: "Load Balancing" },
            { id: 7, title: "Dedicated SSL" },
            { id: 8, title: "Rate Limit & Page Rules" },
            { id: 9, title: "Protect applications, containers, VMs" },
            { id: 10, title: "Protect APIs & Microservices" },
            { id: 11, title: "24/7 Support" }
        ];


    }

    render() {
        return (
            <section className="pricing_area_four sec_pad">
                <div className="container">
                    <div className="hosting_title text-center">
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">DDevOps Security Addons</h2>
                    </div>
                    <div className="row price_info_two price_info_three">
                        {/* Basic Column */}
                        <div className="col-lg px-0">
                            <div className="price_head">
                                <div className="p_head">
                                    <h5>SECURE PRIVATE TUNNEL</h5>
                                    <p>$9 / mo</p>
                                </div>
                            </div>
                            <div className="price_body">
                                {
                                    this.basicTitles.map(list => (
                                        <div className="pr_list" key={`basic-${list.id}`}>
                                            <div className="price_item">
                                                <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" >{list.title}</h5>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pr_list">
                                    <div className="price_item">
                                        <a href="/Contact" className="price_btn btn_hover">Inquire Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Professional Column */}
                        <div className="col-lg px-0">
                            <div className="price_head">
                                <div className="p_head pro">
                                    <h5>SECURE DNS</h5>
                                    <p>$99 / mo</p>
                                </div>
                            </div>
                            <div className="price_body">
                                {
                                    this.professionalTitles.map(list => (
                                        <div className="pr_list" key={`professional-${list.id}`}>
                                            <div className="price_item">
                                                <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" >{list.title}</h5>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pr_list">
                                    <div className="price_item">
                                        <a href="/Contact" className="price_btn btn_hover">Inquire Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Enterprise Column */}
                        <div className="col-lg px-0">
                            <div className="price_head">
                                <div className="p_head enterprise">
                                    <h5>APPLICATION FIREWALL</h5>
                                    <p>$199 / mo</p>
                                </div>
                            </div>
                            <div className="price_body">
                                {
                                    this.enterpriseTitles.map(list => (
                                        <div className="pr_list" key={`enterprise-${list.id}`}>
                                            <div className="price_item">
                                                <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" >{list.title}</h5>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pr_list">
                                    <div className="price_item">
                                        <a href="/Contact" className="price_btn btn_hover">Inquire Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SecurityAddon;