import React from 'react'
import AnimatedText from 'react-animated-text-content';
import Marquee2 from "../../Components/CompanyMarquee";
import AboutServices from "Components/AboutServices";
import Button from "react-bootstrap/Button";
import Infographics from "assets/image/inforgraphsics.png";
import ServiceDetails from "assets/file/DDevOps-Company-Service-Details-2024.pdf";
import { GiCheckMark } from "react-icons/gi";
import { Link } from 'react-router-dom';
import Testimonials2 from "Components/Testimonials2";
import ServicesExperience1 from "Components/DevopsWorkexperience";
import Portfolio from "Components/Portfolio"
import MetaTags from 'react-meta-tags';
// import Team from "assets/image/team1.svg";
import { Typewriter } from 'react-simple-typewriter'


import "./compny.css";


function Company() {
  return (
    <div>
      <MetaTags>
        <title>About Company | Dedicated DevOps</title>
        <meta name="description" content="We engage the best people for your team, services and projects, and its all about dedicated DevOps company." />
        <meta name="keywords" content="DevOps Company, DDevOps Company, DevOps Solution, DevSecOps, Company, DDevOps" />
      </MetaTags>
      <div className="company-gradiant">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 py-5">

            </div>
          </div>
        </div>

      </div>

      <div className="container">
        <div className="row mt-5 mb-5 f_ab text-center">
          <h2>One stop IT solutions for your{' '}
            <span className='develop-span'>
              <Typewriter
                words={['Developments', 'Operations']}
                loop={5}
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span></h2>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-5">
            <h3 className="heading-aboutus">
              <mark className="mark12">17</mark>YEARS
              <br /> EXPERIENCE
              <br /> IN IT
              <br /> OPERATIONS
            </h3>
          </div>
          <div className="col-lg-6 mt-5">
            <div className="hr1"></div>
            <h4 className="tradional-font">
              Traditional IT operations changed in the past few years, gradually
              evolved into TechOps, DevOps, SecOps, and DevSecOps. Technically,
              all these evolutions bring IT solutions under one umbrella called
              Dedicated DevOps, where strong individuals building an invincible
              team.
            </h4>
          </div>
        </div>
      </div>
      <AboutServices />

      <div className="container ">
        <div className="row ">
          <div className="col-lg-12 mt-5 mb-5 text-center ">
            <h6 className='joint-text-size'>
              Challenges are just opportunities and we are ready to…<Link to="/Contact" className="color">Take the challenge!</Link>
            </h6>
          </div>
        </div>
      </div>

      <div className="container resource-bg">
        <div className="row">
          <div className="col-lg-6 button-margin">
            <h3 class="resource-heading">RESOURCES</h3>
            <h1 className=" main-heading ">
              <span className="heading-span">Get a copy of complete</span>
              <br />
              Company Profile
            </h1>
            <a href={ServiceDetails} target="_blank" rel="noreferrer" >
              <Button className="btn button-background mt-3">
                Download now (1.5MB)
              </Button>
            </a>
          </div>

          <div className="col-lg-6 text-center">
            <img src={Infographics} alt="eccomerce" className="eccomerce-responsive" width={450} height={350} />
          </div>


        </div>
      </div>

      <div className="container">
        <div className="row busniness-text-400">
          <div className="col-lg-12 text-center mt-5">
            <h1 className="business-font">
              We excel in delivering optimal performance.
            </h1>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Warranty Management IT
            </h5>
            <p className="business-p">
              Our service offerings enhance customer experience throughout
              secure & highly functional end-to-end warranty management.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Product Engineering & Services
            </h5>
            <p className="business-p">
              Develop and propose product improvements through periodical and
              accurate testing, repairing & refining every version.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Quality Control System
            </h5>
            <p className="business-p">
              It's more than a responsibility but a guarantee from us to gain
              customer trust with highly reliable quality control system.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Infrastructure Integration Technology
            </h5>
            <p className="business-p">
              At DDevOps, we have a holistic and integrated approach towards
              core modernization to experience technological evolution.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Highly Professional Staffs
            </h5>
            <p className="business-p">
              Having obtained the official & formal training in IT technology
              and technical fields, our staffs know more than what they show.
            </p>
          </div>
          <div className="col-lg-6 mt-4">
            <h5 className="span-color">
              <GiCheckMark /> Information Security Management
            </h5>
            <p className="business-p">
              Information security has been a rising issue lately due to a
              series of scandals from big companies, rest assured, we're here.
            </p>
          </div>
        </div>
      </div>

      <Testimonials2 />

      <div>
        <ServicesExperience1 type="company" />
      </div>

      <div className='container'>
        <div className='row busniness-text-400'>
          <div className='col-lg-12 text-center mt-5'>
            <h1 className='business-font'>Discover our range of tailored Managed <span class="testi-span">IT Services</span></h1>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Shared Operations</h5>
            <p className='business-p'>We provide the most responsive and functional IT design for companies and businesses worldwide.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Cloud Operations</h5>
            <p className='business-p'>It’s possible to simultaneously manage and transform information from one server to another.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Dedicated Opeartions</h5>
            <p className='business-p'>Back up your database, store in a safe and secure place while still maintaining its accessibility.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> DevOps Service</h5>
            <p className='business-p'>DDevOps takes into account all conditions and budgets needed for building infrastructure plan.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> SecOps Service</h5>
            <p className='business-p'>Enhancing the strength and security of firewalls to protect online data from malicious sources.</p>
          </div>
          <div className='col-lg-6 mt-4'>
            <h5 className='span-color'><GiCheckMark /> Design & Development</h5>
            <p className='business-p'>Programming is taken care of by our experienced and professional specialist in IT management.</p>
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="row ">
          <div className="col-lg-12 mt-5 mb-5 text-center ">
            <h6 className='joint-text-size'>
              Join more than <span className='develop-span'> 10,000 </span> businesses using DDevOps to delight <br /> customers and keep themselves on a continuous path of  <br /> advancement.
            </h6>
          </div>
        </div>
      </div>

      <div>
        <Portfolio />
      </div>

      <div>
        <Marquee2 />
      </div>

    </div>

  )
}

export default Company